/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Pagination,
} from "@mui/material"
import React, { useEffect, useState } from "react"

import { DateTime } from "luxon"
import {
  and,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore"
import { auth, db, functions } from "../../firebase"
import { PopupService } from "../../react-ui-components/components/PopupService"
import { Formik } from "formik"
import { IRaffle } from "../../react-ui-components/components/models"
import dayjs from "dayjs"
import { httpsCallable } from "firebase/functions"
import htmr from "htmr"

export const sendTicketsToUsers = httpsCallable(functions, "sendTicketsToUsers")

/**
 * UserTickets component fetches and displays user tickets and available raffles.
 *
 * @param {Object} props - Component props.
 * @param {string} props.userId - The ID of the user whose tickets are being fetched.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @component
 *
 * @example
 * return (
 *   <UserTickets userId="user123" />
 * )
 *
 * @remarks
 * This component uses Firebase Firestore to fetch user tickets and available raffles.
 * It uses Formik for form handling and validation.
 *
 * @requires
 * - `db` - Firestore database instance.
 * - `IRaffle` - Interface for raffle data.
 * - `sendTicketsToUsers` - Function to send tickets to users.
 * - `dayjs` - Library for date manipulation.
 *
 * @see {@link https://formik.org/|Formik}
 * @see {@link https://day.js.org/|dayjs}
 */
const UserTickets = ({ userId }: { userId: string }) => {
  const [tickets, setTickets] = useState<any>([])
  const [raffles, setRaffles] = useState<IRaffle[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)
  const [totalTickets, setTotalTickets] = useState<number>(0)
  const [disabledTickets, setDisabledTickets] = useState<number>(0)
  const [expiredTickets, setExpiredTickets] = useState<number>(0)
  const ticketsPerPage = 10

  const fetchTickets = async () => {
    try {
      setLoading(true)
      const ticketsCollection = collection(db, `users/${userId}/tickets`)
      const ticketSnapshot = await getDocs(ticketsCollection)
      const ticketList = ticketSnapshot.docs.map((doc) => doc.data())
      const activeTickets = ticketList.filter(
        (ticket) => !ticket.disabled && ticket.expirationDate >= Date.now(),
      )
      activeTickets.sort((a, b) => b.createdDate - a.createdDate)
      setTickets(activeTickets)
      setTotalTickets(activeTickets.length)
      setDisabledTickets(ticketList.filter((ticket) => ticket.disabled).length)
      setExpiredTickets(ticketList.filter((ticket) => ticket.expirationDate < Date.now()).length)
    } catch (error) {
      console.error("Error fetching tickets: ", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchRaffles = async () => {
    try {
      setLoading(true)
      const col = query(
        collection(db, `raffles`),
        and(
          where("disabled", "==", false),
          where("raffleDate", ">=", new Date().getMilliseconds()),
        ),
      )
      const snapshot = await getDocs(col)
      const list: IRaffle[] = snapshot.docs.map((doc) => doc.data() as IRaffle)
      setRaffles(list)
    } catch (error) {
      console.error("Error fetching raffles: ", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchRaffles()
    fetchTickets()
  }, [userId])

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const paginatedTickets = tickets.slice((page - 1) * ticketsPerPage, page * ticketsPerPage)

  return (
    <Box className="flex flex-col gap-5 items-center justify-center h-screen">
      <Typography variant="h6">User ID: {userId}</Typography>
      <Box sx={{ minWidth: 120, marginTop: 2 }}>
        <Formik
          initialValues={{ raffleId: "", ticketsCount: "" }}
          validate={(values) => {
            const errors: any = {}
            if (!values.raffleId) {
              errors.raffleId = "Required"
            } else if (
              !values.ticketsCount ||
              isNaN(Number(values.ticketsCount)) ||
              Number(values.ticketsCount) <= 0
            ) {
              errors.ticketsCount = "Invalid Tickets Count"
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setLoading(true)
              const response = await sendTicketsToUsers({
                ...values,
                userId,
              })
              console.log("Response: ", response)
              PopupService.pushPopup("Tickets sent successfully!")
            } catch (error) {
              console.error("Error sending tickets: ", error)
              PopupService.pushPopup("Failed to send tickets.")
            } finally {
              setSubmitting(false)
              setLoading(false)
              fetchTickets()
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth className="mt-5">
                <InputLabel id="raffles-selector-label">{"Select Raffle"}</InputLabel>
                <Select
                  className="flex flex-column gap-10"
                  label={"Select Raffle"}
                  labelId="raffles-selector-label"
                  name="raffleId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.raffleId}
                >
                  {raffles?.map((item, i) => (
                    <MenuItem key={i} value={item.id} className="gap-2">
                      <Typography color="error">
                        {!item.raffleDate ? "No Date" : dayjs(item.raffleDate).format("MM/DD/YYYY")}
                      </Typography>
                      <Typography color="success">{item.name}</Typography>
                      <Typography color="secondary">{item.id}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.raffleId && touched.raffleId && (
                <Typography color="error">{errors.raffleId}</Typography>
              )}

              <FormControl fullWidth className="mt-5">
                <InputLabel>Tickets Count</InputLabel>
                <Input
                  type="number"
                  name="ticketsCount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ticketsCount}
                ></Input>
              </FormControl>
              {errors.ticketsCount && touched.ticketsCount && (
                <Typography color="error">{errors.ticketsCount}</Typography>
              )}

              <Button
                fullWidth
                className="mt-5"
                variant="outlined"
                color="success"
                disabled={isSubmitting || !!errors.raffleId || !!errors.ticketsCount}
                type="submit"
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box className="flex flex-row gap-5 items-center justify-center w-full">
            <Typography>Total Tickets: {totalTickets}</Typography>
            <Typography>Disabled Tickets: {disabledTickets}</Typography>
            <Typography>Expired Tickets: {expiredTickets}</Typography>
          </Box>
          <Box className="flex flex-row flex-wrap gap-5 items-center justify-center w-50 h-50 overflow-auto">
            {paginatedTickets?.map(
              (
                item: {
                  id: string
                  isWinner: boolean
                  code:
                    | string
                    | number
                    | boolean
                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                    | Iterable<React.ReactNode>
                    | null
                    | undefined
                  payerUID: any
                  expirationDate: number
                  createdDate: number
                  createdBy: string
                  raffleType: string
                },
                i: number,
              ) => {
                return (
                  <Box key={i}>
                    <Card
                      sx={{ mx: "auto", mt: 4 }}
                      style={{
                        backgroundColor: item.isWinner ? "green" : "transparent",
                        borderWidth: 1,
                      }}
                    >
                      <CardContent>
                        <Box className="flex flex-col gap-1">
                          <Typography gutterBottom variant="h6" component="div">
                            {item.code}
                          </Typography>
                          <Typography>
                            Created Date: {dayjs(item.createdDate).format("MM/DD/YYYY")}
                          </Typography>
                          <Typography>Created By: {item.createdBy}</Typography>
                          <Typography>
                            Expiration Date: {dayjs(item.expirationDate).format("MM/DD/YYYY")}
                          </Typography>
                          <Typography>
                            Raffle Type: {item.raffleType && htmr(item.raffleType)}
                          </Typography>
                          <Box className="flex flex-row gap-3">
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={async () => {
                                try {
                                  setLoading(true)
                                  await deleteDoc(
                                    doc(collection(db, `users/${userId}/tickets/${item.id}`)),
                                  )
                                  PopupService.pushPopup("Ticket deleted successfully!")
                                  fetchTickets()
                                } catch (error) {
                                  console.error("Error deleting ticket: ", error)
                                  PopupService.pushPopup("Failed to delete ticket.")
                                } finally {
                                  setLoading(false)
                                }
                              }}
                            >
                              Delete
                            </Button>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={async () => {
                                try {
                                  setLoading(true)
                                  await updateDoc(
                                    doc(collection(db, `users/${userId}/tickets/${item.id}`)),
                                    {
                                      disabled: true,
                                    },
                                  )
                                  PopupService.pushPopup("Ticket disabled successfully!")
                                  fetchTickets()
                                } catch (error) {
                                  console.error("Error disabling ticket: ", error)
                                  PopupService.pushPopup("Failed to disable ticket.")
                                } finally {
                                  setLoading(false)
                                }
                              }}
                            >
                              Disable
                            </Button>
                            <Button
                              variant="outlined"
                              color="success"
                              onClick={async () => {
                                try {
                                  setLoading(true)
                                  await sendTicketsToUsers({
                                    userId,
                                    ticketId: item.id,
                                  })
                                  PopupService.pushPopup("Ticket sent successfully!")
                                  fetchTickets()
                                } catch (error) {
                                  console.error("Error sending ticket: ", error)
                                  PopupService.pushPopup("Failed to send ticket.")
                                } finally {
                                  setLoading(false)
                                }
                              }}
                            >
                              Send
                            </Button>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                )
              },
            )}
          </Box>
          <Pagination
            count={Math.ceil(tickets.length / ticketsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            className="mt-5"
          />
        </>
      )}
    </Box>
  )
}

export default UserTickets
