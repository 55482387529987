// LocationForm.tsx

import React, { useState } from "react"
import Form from "@rjsf/mui"
import { JSONSchema7 } from "json-schema"
import { Button } from "@mui/material"
import validator from "@rjsf/validator-ajv8"
import { IChangeEvent } from "@rjsf/core"
import { RJSFSchema } from "@rjsf/utils"
import { DEFAULT_AVAILABLE_LOCATION } from "../../react-ui-components/components/location/LocationSelector"
import { ILocation } from "../../react-ui-components/components/models/ILocation"

// 1. Define the JSON Schema
const schema: JSONSchema7 = {
  title: "Available Locations",
  type: "object",
  description: "Add or remove countries, states, and cities.",
  // "additionalProperties" at the top-level allows adding new countries.
  additionalProperties: {
    type: "object",
    // "additionalProperties" at this level allows adding new states within each country.
    additionalProperties: {
      type: "array",
      // Each state is an array of city names (strings).
      items: {
        type: "string",
      },
    },
  },
}

// 2. (Optional) Define the UI Schema for better control over add/remove buttons
const uiSchema = {
  // Options for the top-level object (countries)
  "ui:options": {
    addable: true,
    orderable: false, // set to true if you want to reorder countries
    removable: true,
  },
  // We also need to set nested additionalProperties options
  additionalProperties: {
    "ui:options": {
      addable: true,
      removable: true,
      orderable: false,
    },
    additionalProperties: {
      "ui:options": {
        addable: true,
        removable: true,
        orderable: false,
      },
    },
  },
}

const EditAvailableLocations: React.FC<{
  currentLocations: ILocation
  updateLocation: React.Dispatch<React.SetStateAction<ILocation>>
  sugmit: (location: ILocation) => void
}> = ({ currentLocations, updateLocation, sugmit }) => {
  // 3. Keep track of form data in state
  const [formData, setFormData] = useState<ILocation>(
    currentLocations || DEFAULT_AVAILABLE_LOCATION,
  )

  // 4. Handlers
  const handleChange = (e: IChangeEvent<any, RJSFSchema, any>, _id?: string): void => {
    setFormData(e.formData as ILocation)
    updateLocation(e.formData)
  }

  const handleSubmit = (e: any) => {
    console.log("Submitted data:", e.formData)
    sugmit(e.formData)
  }

  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      formData={formData}
      onChange={handleChange}
      onSubmit={handleSubmit}
      validator={validator}
    >
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </Form>
  )
}

export default EditAvailableLocations
