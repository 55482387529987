import { Button, Card, CardContent, Typography } from "@mui/material"
// UserList.js
import React, { useEffect, useState } from "react"
import {
  collection,
  doc,
  documentId,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore"

import { DateTimePicker } from "@mui/x-date-pickers"
import { TicketItem } from "./Tickets"
import dayjs from "dayjs"
import { db } from "../firebase"
import { LoadingService } from "../react-ui-components/components/LoadingService"
import { PopupService } from "../react-ui-components/components/PopupService"

// import pLimit from "p-limit"
const PAGE_SIZE = 60

const FixTickets = ({ raffle }: any) => {
  const [users, setUsers] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [usersTickets, setUsersTickets] = useState<any>({})
  const [newDate, setNewDate] = useState<any>(raffle.expirationDate)
  const [isUpdating, setIsUpdating] = useState(false)

  // const fetchUsers = async (isNextPage = false) => {
  //   try {
  //     LoadingService.show()

  //     let ticketsQuery = query(collection(db, `raffles/${raffle.id}/tickets`), limit(PAGE_SIZE))

  //     if (isNextPage && lastDoc) {
  //       ticketsQuery = query(ticketsQuery, startAfter(lastDoc))
  //     }

  //     // Step 1: Fetch all tickets from the specific raffle
  //     const ticketsSnapshot = await getDocs(ticketsQuery)
  //     const tickets = ticketsSnapshot.docs.map((doc) => doc.data())

  //     if (tickets.length < PAGE_SIZE) {
  //       setHasMore(false)
  //     }

  //     if (isNextPage) {
  //       setPages((prevPages) => [...prevPages, tickets])
  //       setCurrentPageIndex((prevIndex) => prevIndex + 1)
  //     } else {
  //       setPages([tickets])
  //       setCurrentPageIndex(0)
  //     }

  //     setLastDoc(ticketsSnapshot.docs[ticketsSnapshot.docs.length - 1])

  //     // Extract all unique user IDs (payerUID and code) from tickets
  //     // const userIds = [...new Set(tickets.flatMap((ticket) => [ticket.payerUID]))].filter(Boolean)

  //     const codes = [...new Set(tickets.flatMap((ticket) => [ticket.code]))].filter(Boolean)

  //     // Step 2: Batch the requests to fetch users by userIds
  //     const userChunks = []

  //     const chunkSize = 10 // Define the batch size you want to fetch at a time

  //     for (let i = 0; i < codes.length; i += chunkSize) {
  //       const chunk = codes.slice(i, i + chunkSize)
  //       userChunks.push(chunk)
  //     }

  //     let usersQuery = query(
  //       collection(db, "users"),
  //       or(where("name", "!=", "")),
  //     )

  //     const usersSnapshot = await getDocs(usersQuery)

  //     // for (const userDoc of usersSnapshot.docs) {

  //     //   let usersCodesQuery = query(collection(db, `users/${userDoc.id}/tickets`), where('code','in', codes));
  //     //   const usersTicketsSnapshot = await getDocs(usersCodesQuery);

  //     // }

  //     const userPromises = usersSnapshot.docs.map(async (userDoc) => {
  //       let usersCodesQuery = query(
  //         collection(db, `users/${userDoc.id}/tickets`),
  //         where("code", "in", codes),
  //       )

  //       const countResponse = await getCountFromServer(usersCodesQuery)
  //       return countResponse ? userDoc.data() : null
  //     })

  //     // const userPromises = userChunks.map(async (chunk) => {
  //     //   const usersQuerySnapshot = await getDocs(
  //     //     query(collection(db, "users"), where(documentId(), "in", chunk)),
  //     //   )
  //     //   return usersQuerySnapshot.docs.map((doc) => ({
  //     //     id: doc.id,
  //     //     ...doc.data(),
  //     //   }))
  //     // })

  //     const usersLists = await Promise.all(userPromises)
  //     const users = usersLists.flat()

  //     setUsers(users)

  //     // const codeChunks = []
  //     // for (let i = 0; i < codes.length; i += chunkSize) {
  //     //   const chunk = codes.slice(i, i + chunkSize)
  //     //   codeChunks.push(chunk)
  //     // }

  //     // load
  //   } catch (error) {
  //     console.error("Error fetching users:", error)
  //   } finally {
  //     LoadingService.hide()
  //     setIsLoading(false)
  //   }
  // }

  useEffect(() => {
    // const fetchTickets = async () => {
    //   const ticketsSnapshot = await getDocs(collection(db, `raffles/${raffle.id}/tickets`))
    //   const ticketList = ticketsSnapshot.docs.map((doc) => doc.data())
    //   setTickets(ticketList)
    // }

    const fetchUsers = async (raffle: { id: unknown }, isNextPage = false) => {
      try {
        LoadingService.show()

        // Define the initial query for tickets
        // let ticketsQuery = query(collection(db, `raffles/${raffle.id}/tickets`), limit(PAGE_SIZE))

        // // Add pagination if it's a next page request
        // if (isNextPage && lastDoc) {
        //   ticketsQuery = query(ticketsQuery, startAfter(lastDoc))
        // }

        // const ticketsSnapshot = await getDocs(ticketsQuery)
        // const tickets = ticketsSnapshot.docs.map((doc) => doc.data())
        // setRaffleTickets(tickets)
        // if (tickets.length < PAGE_SIZE) {
        //   setHasMore(false)
        // }

        // if (isNextPage) {
        //   setPages((prevPages) => [...prevPages, tickets])
        //   setCurrentPageIndex((prevIndex) => prevIndex + 1)
        // } else {
        //   setPages([tickets])
        //   setCurrentPageIndex(0)
        // }

        // setLastDoc(ticketsSnapshot.docs[ticketsSnapshot.docs.length - 1])

        // const codes = [...new Set(tickets.flatMap((ticket) => [ticket.code]))].filter(Boolean)

        // const chunkSize = 30
        const codesPromises: any[] = []

        const usersQuery = query(collection(db, "users"), or(where("name", "!=", "")))
        const usersSnapshot = await getDocs(usersQuery)

        usersSnapshot.docs.forEach((doc) => {
          const usersQuery = query(
            collection(db, `users/${doc.id}/tickets`),
            where("raffleId", "==", raffle.id),
          )

          codesPromises.push({ userId: doc.id, query: usersQuery })

          // for (let i = 0; i < codes.length; i += chunkSize) {
          //   const codeChunk = codes.slice(i, i + chunkSize)

          //   const usersQuery = query(
          //     collection(db, `users/${doc.id}/tickets`),
          //     where("raffleId", "in", codeChunk),
          //   )

          //   codesPromises.push({ userId: doc.id, promise: getCountFromServer(usersQuery) })
          // }
        })

        const userIds = []
        const loadedUsers: any = {}
        codesPromises.forEach(async ({ userId, query }) => {
          const codesSnapshots = await getDocs(query)
          // const data = codesSnapshots.data()

          if (codesSnapshots.docs.length) {
            // const tickets = await getDocs(
            //   collection(db, `users/${userId}/tickets`),
            //   where("raffleId", "==", raffle.id),
            // )
            setUsersTickets((x: any) => ({
              ...x,
              [userId]: codesSnapshots.docs.map((x) => x.data()),
            }))

            userIds.push(userId)
            let user: any = {}
            if (!loadedUsers[userId]) {
              user = await getDoc(doc(db, `users/${userId}`))
              loadedUsers[userId] = user
            } else {
              user = loadedUsers[userId]
            }

            setUsers((users: any[]) => {
              if (!users.find((x: { uid: any }) => x.uid === userId)) {
                return [
                  ...users,
                  { ...user.data(), uid: userId, ticketsCount: codesSnapshots.docs.length },
                ]
              }
              return users
            })
          } else {
            console.log("usersSnapshots---->", "NOTHING")
          }

          // const users = usersSnapshots.flatMap((snapshot) =>
          //   snapshot.docs.map((userDoc) => {
          //     const data = userDoc.data()
          //     users.push(data)
          //     return data
          //   }),
          // )
        })

        // const usersSnapshots = await Promise.all(userPromises)
        // const users = usersSnapshots.flatMap((snapshot) =>
        //   snapshot.docs.map((userDoc) => userDoc.data()),
        // )
      } catch (error) {
        console.error("Error fetching users:", error)
      } finally {
        LoadingService.hide()
        setIsLoading(false)
      }
    }

    fetchUsers(raffle)
    // fetchTickets()
  }, [raffle])

  const handleUpdateTickets = async () => {
    try {
      LoadingService.show()

      await handleUpdateRafflesTickets()
      for (let index = 0; index < users.length; index++) {
        const user = users[index]
        await handleUpdateUsersTickets(user)
      }
    } catch (error: any) {
      PopupService.setPopup(error.message)
    } finally {
      LoadingService.hide()
    }
  }

  const handleUpdateRafflesTickets = async () => {
    setIsUpdating(true)

    try {
      const ticketsRef = collection(db, `raffles/${raffle.id}/tickets`)
      const ticketsSnapshot = await getDocs(ticketsRef)

      const updatePromises = ticketsSnapshot.docs.map((ticketDoc) => {
        const ticketRef = doc(db, `raffles/${raffle.id}/tickets`, ticketDoc.id)
        return updateDoc(ticketRef, { expirationDate: newDate })
      })

      await Promise.all(updatePromises)
      alert("All Raffle tickets updated successfully!")
    } catch (error) {
      console.error("Error updating tickets' expiration date:", error)
    } finally {
      setIsUpdating(false)
    }
  }

  const handleUpdateUsersTickets = async (user: { uid: any; name: any }) => {
    setIsUpdating(true)

    try {
      const ticketsRef = collection(db, `users/${user.uid}/tickets`)
      const ticketsSnapshot = await getDocs(ticketsRef)

      const updatePromises = ticketsSnapshot.docs.map((ticketDoc) => {
        const ticketRef = doc(db, `users/${user.uid}/tickets`, ticketDoc.id)
        return updateDoc(ticketRef, { expirationDate: newDate })
      })

      await Promise.all(updatePromises)
      alert(`All User ${user.name} tickets updated successfully!`)
    } catch (error) {
      console.error("Error updating tickets' expiration date:", error)
    } finally {
      setIsUpdating(false)
    }
  }

  // const filteredUsers = users.filter((user) =>
  //   tickets.some((ticket) => ticket.payerUID === user.id || ticket.code === user.id),
  // )

  return (
    <div className="flex flex-col items-center mt-8">
      <Typography variant="h4" component="h2" className="mb-4">
        Users Participating in the Raffle
      </Typography>
      <div className="flex flex-row items-center gap-1">
        <DateTimePicker
          label="New Expiration Date"
          value={dayjs(newDate)}
          slotProps={{ textField: { variant: "outlined" } }}
          onChange={(newValue) => {
            const val = newValue ? newValue.valueOf() : ""
            setNewDate(val)
            console.log("DateTimePicker---->", dayjs(val))
          }}
        />
        <Button onClick={handleUpdateTickets} disabled={isUpdating} variant="contained">
          {isUpdating ? "Updating..." : "Update Expiration Date for All Tickets"}
        </Button>
      </div>
      {isLoading ? (
        <Typography variant="body1" component="p">
          Loading...
        </Typography>
      ) : users.length === 0 ? (
        <Typography variant="body1" component="p" className="text-gray-500">
          No users found for this raffle.
        </Typography>
      ) : (
        users.map(
          (user: {
            uid:
              | boolean
              | React.ReactElement<any, string | React.JSXElementConstructor<any>>
              | Iterable<React.ReactNode>
              | React.Key
              | null
              | undefined
            name:
              | string
              | number
              | boolean
              | React.ReactElement<any, string | React.JSXElementConstructor<any>>
              | Iterable<React.ReactNode>
              | React.ReactPortal
              | null
              | undefined
            email:
              | string
              | number
              | boolean
              | React.ReactElement<any, string | React.JSXElementConstructor<any>>
              | Iterable<React.ReactNode>
              | React.ReactPortal
              | null
              | undefined
            ticketsCount: any
          }) => (
            <>
              <Card key={user.uid as any} className="mb-4 w-full md:w-1/2 p-4 shadow-lg">
                <CardContent>
                  <Typography variant="h6" component="div">
                    {user.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Email: {user.email}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Id: {user.uid} Tickets Count ({user.ticketsCount || 0})
                  </Typography>
                </CardContent>
              </Card>

              {/* <Button
              onClick={async () => {
                setUsersTickets()
              }}
              disabled={isLoading}
              variant="contained"
            >
              Load Tickets
            </Button> */}
              {/* {<div className="flex m-10">usersTickets:{JSON.stringify(usersTickets?.[user.uid])}</div>} */}
              <div className="flex flex-row gap-1 auto w-50">
                {usersTickets?.[user.uid as any]?.map(
                  (ticket: unknown, i: React.Key | null | undefined) => {
                    return <TicketItem key={i} item={ticket} />
                  },
                ) || null}
              </div>
            </>
          ),
        )
      )}

      {/* <div className="flex justify-center my-4 space-x-2">
        {pages.map((_, index) => (
          <Button
            key={index}
            onClick={() => setCurrentPageIndex(index)}
            variant={currentPageIndex === index ? "contained" : "outlined"}
          >
            {index + 1}
          </Button>
        ))}
        {hasMore && (
          <Button onClick={() => fetchUsers(true)} disabled={isLoading} variant="contained">
            Load More
          </Button>
        )}
      </div> */}
    </div>
  )
}

export default FixTickets

// const fetchUsers = async () => {
//   const limit = pLimit(10) // Set a limit on the number of concurrent Firestore requests

//   try {
//     LoadingService.show()

//     const usersSnapshot = await getDocs(collection(db, "users"))
//     const userList = await Promise.all(
//       usersSnapshot.docs.map((doc) =>
//         limit(async () => {
//           const ticketsSnapshot = await getDocs(collection(db, `users/${doc.id}/tickets`))

//           return {
//             id: doc.id,
//             tickets: ticketsSnapshot.docs.map((x) => x.data()),
//             ...doc.data(),
//           }
//         }),
//       ),
//     )

//     setUsers(userList)
//   } catch (error) {
//     console.error("Error fetching users:", error)
//   } finally {
//     LoadingService.hide()
//   }
// }
