import {
  ILocation,
  ILocationContext,
  ILocationItem,
} from '../models/ILocation';
import React, { createContext, useContext } from 'react';

import { CityLocationSelector } from './CityLocationSelector';
import { CountryLocationSelector } from './CountryLocationSelector';
import { InputLabel } from '@mui/material';
import { StateLocationSelector } from './StateLocationSelector';
import { useTranslation } from 'react-i18next';

export const DEFAULT_COUNTRY = {
  currency: 'USD',
  disabled: false,
  display: 'United States',
  icon: '',
  key: 'US',
};
export const DEFAULT_AVAILABLE_LOCATION: ILocation = {
  US: {
    Florida: ['Orlando'],
    'New York': ['Queens'],
  },
};

const loc: ILocationContext = {
  currentLocation: {
    country: 'US',
    city: '',
    state: '',
  },
  availableLocations: {
    DO: {
      Espaillat: ['Villa Magante'],
    },
  },
  setCurrentLocation: null,
  setAvailableLocations: null,
};

export const LocationContext = createContext(loc);

export const useLocation = (context: any = null) => {
  return useContext<ILocationContext>(context ? context : LocationContext);
};

const LocationSelector: React.FC<{
  onChange: (name: string, value: string) => void;
  label: string;
  value: ILocationItem;
  rootProps?: any
}> = (props) => {
  const { onChange, value, label, rootProps } = props;

  const { t } = useTranslation();

  return (
    <div {...rootProps}>
      <InputLabel>{t(label || 'Select Location')}</InputLabel>
      <CountryLocationSelector value={value?.country} onChange={onChange} />
      <StateLocationSelector value={value?.state} onChange={onChange} />
      <CityLocationSelector value={value?.city} onChange={onChange} />
    </div>
  );
};

export default LocationSelector;
