import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { ILocationContext } from '../models/ILocation';
import { motion } from 'framer-motion';
import { useLocation } from './LocationSelector';
import { useTranslation } from 'react-i18next';

export const StateLocationSelector = ({ value, onChange, context }: any) => {
  const { t } = useTranslation();
  const {
    availableLocations,
    currentLocation,
    loading,
    setCurrentLocation,
  }: ILocationContext = useLocation(context);

  const [states, setStates] = useState<string[]>([]);

  useEffect(() => {
    updateLocation(value);
  }, [value]);

  useEffect(() => {
    const country = currentLocation?.country || '';
    const arr = Object.keys(availableLocations?.[country] || {});
    setStates(arr);
  }, [currentLocation]);

  function updateLocation(state: string) {
    const country = currentLocation?.country || '';
    const arr = Object.keys(availableLocations?.[country] || {});

    setCurrentLocation &&
      setCurrentLocation({
        country: currentLocation.country || '',
        state: state,
        states: arr,
        cities: !state
          ? []
          : !availableLocations?.[country]?.[state]
            ? []
            : availableLocations[country][state],
        city: '',
      });
    setStates(arr);
  }

  const handleChange = (event: { target: { value: any } }, child: any) => {
    const value = event.target.value;
    updateLocation(value);

    onChange && onChange('state', value);
  };

  return (
    <div className='p-4'>
      {loading ? (
        <CircularProgress />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <FormControl fullWidth className='mb-4' disabled={!states?.length}>
            <InputLabel>{t('Select State')}</InputLabel>
            <Select
              value={currentLocation.state || ''}
              onChange={handleChange}
              displayEmpty
              renderValue={(value) =>
                value ? (
                  <div className='flex items-center gap-1'>
                    <div>{t('' + value || 'Any')}</div>
                  </div>
                ) : (
                  <span>{t('Select State')}</span>
                )
              }
            >
             <MenuItem value="">
                <div className='flex items-center gap-1'>
                  <p>🌍{' '}
                    {t('Any')}
                  </p>
                </div>
              </MenuItem>
              {!states
                ? null
                : states.map((val: any, i: number) => (
                  <MenuItem key={i} value={val}>
                    <div className='flex items-center gap-1'>
                      <span> {t(val || 'Any')}</span>
                    </div>
                  </MenuItem>
                ))}

            </Select>
          </FormControl>
        </motion.div>
      )}
    </div>
  );
};
