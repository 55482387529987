import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Divider,
  IconButton,
  Typography,
  debounce,
} from "@mui/material"
import { ArrowBack, ArrowForward } from "@mui/icons-material"
import { DateTimeWidget, SelectWidget } from "../CustomWidgets"
// RaffleEdit.js
import React, { useEffect, useRef, useState } from "react"
import { collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore"
import { db, storage } from "../../firebase"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { useNavigate, useParams } from "react-router-dom"

import CountryData from "country-data"
import CustomFieldTemplate from "../CustomFieldTemplate"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Theme as MuiTheme } from "@rjsf/material-ui"
import SwipeableViews from "react-swipeable-views"
import TicketPreview from "../TicketPreview"
import { sanitizeData } from "../../utils"
import { v4 as uuidv4 } from "uuid"
import validator from "@rjsf/validator-ajv8"
import { withTheme } from "@rjsf/core"

const ThemedForm = withTheme(MuiTheme)

export function getCountryInfo(code: string | number) {
  // console.log("CountryData.countries----CountryData.countries[code]-->", CountryData.countries[code])
  /*
    {
        "alpha2": "DO",
        "alpha3": "DOM",
        "countryCallingCodes": ["+1 809", "+1 829", "+1 849"],
        "currencies": ["DOP"],
        "emoji": "🇩🇴",
        "ioc": "DOM", 
        "languages": ["spa"],
        "name": "Dominican Republic",
        "status": "assigned"
    }
    */
  return CountryData.countries?.[code] || CountryData.countries["US"]
}

const RaffleEdit = () => {
  const { id }: any = useParams()
  const navigate = useNavigate()
  const [raffle, setRaffle] = useState<any>(null)
  const [countries, setCountries] = useState<any>([])
  const [provinces, setProvinces] = useState<any>([])
  const [selectedCountry, setSelectedCountry] = useState("")
  const [selectedProvince, setSelectedProvince] = useState("")
  const [formData, setFormData] = useState<any>({
    value: 0,
  })
  const [availableCountryInfo, setAvailableCountryInfo] = useState<any>({})
  const [availableLocations, setAvailableLocations] = useState({})
  const [raffleTypes, setRaffleTypes] = useState([])
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const formRef = useRef(null)

  useEffect(() => {
    const fetchRaffle = async () => {
      const docRef = doc(db, "raffles", id)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const raffleData = docSnap.data()
        setRaffle({ id: docSnap.id, ...raffleData })
        setSelectedCountry(raffleData.country || "")
        setFormData({
          ...raffleData,
          country: raffleData.country || "",
          province: raffleData.province || "",
          street: raffleData.street || "",
          ticketDesign: raffleData.ticketDesign || null,
          raffleImages: raffleData.raffleImages || [],
        } as any)
        // setFormData({ ticketDesign: {}, ...raffleData });
      }
    }

    const fetchDisplayContentConfig = async () => {
      const querySnapshot = await getDoc(doc(db, "app-configurations", "display-content-config"))
      const data: any = querySnapshot.data()

      const locs = data?.availableLocations || []
      setAvailableCountryInfo(locs)
      const countryData = locs?.map((item: { country: any }) => getCountryInfo(item.country).name)
      setCountries(countryData)

      setRaffleTypes(data.raffleTypes)
    }

    // const fetchCountries = async () => {
    //     const querySnapshot = await getDoc(doc(db, 'app-configurations', 'countries'));
    //     const data = querySnapshot.data();
    //     setAvailableCountryInfo(data);
    //     const countryData = Object.keys(data).map(k => data[k].display);
    //     setCountries(countryData);
    // };

    // const fetchAvailableLocations = async () => {
    //     const querySnapshot = await getDoc(doc(db, 'app-configurations', 'availableLocations'));
    //     const data = querySnapshot.data();
    //     setAvailableLocations(data);
    // };

    // const fetchProvinces = async () => {
    //     // const querySnapshot = await getDocs(collection(db, 'provinces'));
    //     // const provinceData = querySnapshot.docs.map(doc => doc.data().name);
    //     // setProvinces(provinceData);
    // };

    fetchRaffle()
    // fetchCountries();
    // fetchProvinces();
    // fetchAvailableLocations();
    fetchDisplayContentConfig()
  }, [id])

  // useEffect(() => { console.log('formData----->', formData); }, [formData]);

  const handleSubmit = async ({ formData }: any) => {
    const sanitizedData = sanitizeData(formData)
    sanitizedData.raffleDate = sanitizedData.raffleDate
      ? new Date(sanitizedData.raffleDate).getTime()
      : null
    const docRef = doc(db, "raffles", id)
    await updateDoc(docRef, sanitizedData)
    navigate(`/raffles/${id}`)
  }

  const handleChange = debounce(({ formData }) => {
    // const data = data.formData
    if (formData.country && availableCountryInfo?.length) {
      const countryInfo = availableCountryInfo.find(
        (item: { country: any }) => getCountryInfo(item.country).name === formData.country,
      )
      const states = countryInfo?.provinces?.map((x: { name: any }) => x.name) || []
      setProvinces(states)
    }
    // if (formData.country && availableCountryInfo && Object.keys(availableCountryInfo).length) {
    //     const countryInfo = Object.keys(availableCountryInfo).find(k => availableCountryInfo[k].display === formData.country);
    //     const states = Object.keys(availableLocations?.[countryInfo]);
    //     setProvinces(states);
    // }
    // console.log('formData---->', formData);
    setFormData(formData)
  }, 300)

  const handleImageUpload = async (event: { target: { files: any[] } }) => {
    const file = event.target.files[0]
    if (!file) return

    const uniqueId = uuidv4()
    const storageRef = ref(storage, `raffle-images/${uniqueId}-${file.name}`)
    await uploadBytes(storageRef, file)
    const downloadURL = await getDownloadURL(storageRef)
    //{ id: uniqueId, name: file.name, url: downloadURL }
    const updatedImages = [...(formData.updatedImages || []), downloadURL]
    setFormData({ ...formData, raffleImages: updatedImages })
  }

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? formData.images.length - 1 : prevIndex - 1,
    )
  }

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === formData.images.length - 1 ? 0 : prevIndex + 1,
    )
  }

  if (!raffle) return <div>Loading...</div>

  const schema: any = {
    title: "Edit Raffle",
    type: "object",
    required: ["name", "order", "description", "raffleDate", "termsText", "subtitle", "type"],
    properties: {
      name: { type: "string", title: "Name" },
      value: { type: "number", title: "Value" },
      order: { type: "number", title: "Order" },
      description: { type: "string", title: "Description" },
      raffleDate: { type: "string", title: "Raffle Date", format: "date-time" },
      termsText: { type: "string", title: "Terms Text" },
      subtitle: { type: "string", title: "Subtitle" },
      winTicketPerLike: { type: "boolean", title: "Win Ticket Per Like" },

      raffleImages: {
        type: "array",
        title: "Images",
        items: {
          type: "string",
          //     properties: {
          //         id: { type: "string" },
          //         name: { type: "string" },
          //         url: { type: "string" },
          //     },
        },
      },
      rafflesImagesLink: { type: "string", title: "Raffles Images Link" },
      country: {
        type: "string",
        title: "Country",
        ...(countries?.length ? { enum: countries } : {}),
      },
      province: {
        type: "string",
        title: "Province",
        ...(provinces?.length ? { enum: provinces } : {}),
      },
      // street: { type: "string", title: "Street" },
      height: { type: "number", title: "Height" },
      // interested: { type: "number", title: "Interested" },
      createdBy: { type: "string", title: "Created By" },
      disabled: { type: "boolean", title: "Disabled" },
      // postcode: { type: "string", title: "Postcode" },
      points: { type: "number", title: "Points" },

      type: { type: "string", title: "Type", enum: raffleTypes },
      // value: { type: "number", title: "Value" },
      ticketDesign: {
        type: ["object", "null"],
        title: "Ticket Design",
        properties: {
          backgroundColor: { type: "string", title: "Background Color" },
          color: { type: "string", title: "Color" },
          height: { type: "number", title: "Height" },
          justifyContent: { type: "string", title: "Justify Content" },
          margin: { type: "number", title: "Margin" },
          padding: { type: "number", title: "Padding" },
          textColor: { type: "string", title: "Text Color" },
          width: { type: "number", title: "Width" },
        },
      },
    },
  }

  const uiSchema = {
    raffleDate: {
      "ui:widget": DateTimeWidget,
    },
    termsText: {
      "ui:widget": "textarea",
    },
    country: {
      "ui:widget": SelectWidget,
    },
    province: {
      "ui:widget": SelectWidget,
    },
    raffleTypes: {
      "ui:widget": SelectWidget,
    },
    description: {
      "ui:widget": "textarea",
    },
    // street: {
    //     "ui:widget": SelectWidget,
    // },
  }

  // const templates = {
  //     FieldTemplate: CustomFieldTemplate,
  // };

  return (
    <Container>
      <ThemedForm
        schema={schema}
        formData={formData}
        onChange={handleChange}
        onSubmit={handleSubmit}
        validator={validator}
        uiSchema={uiSchema}
        // templates={templates}
        formContext={formData}
      >
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </ThemedForm>

      <Divider style={{ margin: "20px 0" }} />

      <Typography variant="h6">Images</Typography>
      <input type="file" accept="image/*" onChange={handleImageUpload as any} />
      {formData.images && formData.images.length > 0 && (
        <div style={{ position: "relative", width: "300px", margin: "20px 0" }}>
          <SwipeableViews index={currentImageIndex} onChangeIndex={setCurrentImageIndex}>
            {formData.images.map(
              (
                image: {
                  id: React.Key | null | undefined
                  url: string | undefined
                  name: string | undefined
                },
                index: any,
              ) => (
                <div key={image.id} style={{ padding: "10px", textAlign: "center" }}>
                  <img src={image.url} alt={image.name} style={{ maxWidth: "100%" }} />
                </div>
              ),
            )}
          </SwipeableViews>
          <IconButton
            style={{ position: "absolute", top: "50%", left: "0", transform: "translateY(-50%)" }}
            onClick={handlePrevImage}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            style={{ position: "absolute", top: "50%", right: "0", transform: "translateY(-50%)" }}
            onClick={handleNextImage}
          >
            <ArrowForward />
          </IconButton>
        </div>
      )}
      {/*     
          <Divider style={{ margin: '20px 0' }} />
    
          <Accordion defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Ticket Design (Optional)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ThemedForm
                schema={{
                  type: 'object',
                  properties: {
                    ticketDesign: schema.properties.ticketDesign,
                  },
                }}
                formData={{ ticketDesign: formData.ticketDesign }}
                onChange={handleChange}
                validator={validator}
                uiSchema={{
                  ticketDesign: uiSchema.ticketDesign,
                }}
                FieldTemplate={CustomFieldTemplate}
                formContext={formData}
              >
                <div />
              </ThemedForm>
            </AccordionDetails>
          </Accordion> */}
    </Container>
  )
}

export default RaffleEdit
