import React, {
  useEffect,
  useState,
} from 'react';

import _get from 'lodash/get';

import Form from '@rjsf/material-ui';
import validator from '@rjsf/validator-ajv8';

import { EmptyComponent } from '../components/EmptyComponent';
import {
  CustomObjectFieldTemplate,
} from '../components/utils/CustomObjectFieldTemplate';

const CreateEdit = (props:any) => {
  const {
    data,
    fields,
    schema,
    uiSchema,
    widgets,
    onSubmit,
    onError,
    onChange,
    templates,
  } = props || {};

  const [dynamicData, setDynamicData] = useState();
  const [dynamicSchema, setDynamicSchema] = useState(
    schema || {
      type: 'object',
      properties: {},
    }
  );

  useEffect(() => {
    setDynamicSchema(schema);
  }, [schema]);

  useEffect(() => {
    setDynamicData(data);
  }, [data]);

  const formContext = {
    onChange: (newData: React.SetStateAction<any>) => setDynamicData(newData),
    getFormData: () => dynamicData,
    getDataAtPath: (path: any) => {
      const p = _get(dynamicData, path);
      return p;
    },
    path: '',
  };

  return !schema ? (
    <EmptyComponent message='schema not provided' />
  ) : (
    <Form
      formData={dynamicData}
      fields={fields || {}}
      widgets={widgets || {}}
      schema={dynamicSchema}
      uiSchema={uiSchema || {}}
      validator={validator}
      onChange={onChange}
      onSubmit={onSubmit}
      onError={(error) => onError && onError(error)}
      templates={{
        ObjectFieldTemplate: CustomObjectFieldTemplate,
        ...templates,
      }}
      formContext={formContext}
    />
  );
};

export default CreateEdit;
