import { Button } from "@mui/material"
import CreateEdit from "./CreateEdit"
import MUIDataTable from "mui-datatables"
import React from "react"
import { getData } from "./getData"
import { PopupService } from "../../../react-ui-components/components/PopupService"
import { Loading } from "../../../react-ui-components/components/LoadingService"

export function DataTable({
  hideAddButton,
  setLoading,
  setData,
  loading,
  data,
  columns,
  path,
  bucket,
  addTitle,
  title,
  onAddSuccess,
  getMap,
  schema,
  options,
  imagePropName,
  isCollection,
  onEditSuccess,
  onRowClicked,
  onSuccess,
}: any) {
  if (typeof options === "function") {
    options = options({
      path,
      data,
      onSuccess: () => {
        getData({ setData, path, getMap, setLoading })
      },
      onEditSuccess: (data: any) => {
        onEditSuccess && onEditSuccess(data)
        // getData({
        //   setData,
        //   path,
        //   getMap,
        //   setLoading,
        //   isCollection: isCollection,
        // });
      },
      onRowClick: onRowClicked, //(rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => void;
    })
  }
  return (
    <>
      {hideAddButton ? null : (
        <Button
          variant="outlined"
          style={{ marginBottom: 10 }}
          onClick={() =>
            PopupService.setPopup(({ close }: any) => (
              <CreateEdit
                imagePropName={imagePropName}
                schema={schema}
                path={path}
                bucket={bucket}
                title={addTitle}
                setLoading={setLoading}
                onSuccess={async (result: any) => {
                  onAddSuccess && onAddSuccess({ close, result })
                }}
              />
            ))
          }
        >
          Add
        </Button>
      )}
      {loading ? (
        <Loading />
      ) : (
        <MUIDataTable title={title} data={data} columns={columns} options={options} />
      )}
    </>
  )
}
