/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Add, Delete, Edit } from "@mui/icons-material"
import {
  AddEditComponent,
  addSubcollection,
  deleteCollectionAndImages,
  getSubcollectionData,
  removeSubcollectionProperty,
} from "./GenericListComponent"
import { Button, TextField } from "@mui/material"
import { doc, getDoc } from "firebase/firestore"
import { useCallback, useEffect, useState } from "react"

import React from "react"
import { SelectWidget } from "./components/CustomWidgets"
import { db } from "./firebase"
import { PopupService } from "./react-ui-components/components/PopupService"

/*
 "businesses",
    businessId,
    `config-${configLocation || "loc-001"}`,
    "ads",
*/
const data: any = {
  getListLocation(id: string) {
    return {
      subCollectionDoc: "ads",
      path: ["businesses", id],
      startsWith: "config-",
      propHoldingSubCollection: "ads-config",
    }
  },
  EDIT_TITLE: "Edit Ad",
  ADD_TITLE: "Add Ad",
  LOADING_MESSAGE: "Loading ads...",
  TITLE: "Business Ads",
  IMAGES_BUCKET: "adsImages",

  columnsProperties: [
    { hidden: true, name: "id", label: "Id" },
    { required: false, name: "disabled", label: "Disabled", type: "boolean" },

    { required: true, name: "width", label: "Width" },
    { required: true, name: "height", label: "Height" },
    { required: false, name: "style", label: "Style" },
    { required: true, name: "secondsInterval", label: "Seconds Interval" },
    {
      required: false,
      name: "viewLinkOnPopupIframe",
      label: "View Link On Popup Iframe",
      type: "boolean",
    },
    {
      required: false,
      name: "linkTarget",
      label: "Link Target",
      type: "string",
      enum: ["_self", "_blank", "_top"],
    },
    {
      required: false,
      name: "animation",
      label: "Animation",
      type: "string",
      enum: ["slide", "fade"],
    },
    {
      required: false,
      name: "stopAutoPlayOnHover",
      label: "Stop Auto Play On Hover",
      type: "boolean",
    },

    { required: false, type: "boolean", name: "indicators", label: "Indicators" },
    { required: false, type: "boolean", name: "cycleNavigation", label: "Cycle Navigation" },
    { required: false, type: "boolean", name: "fullHeightHover", label: "Full Height Hover" },
    { required: false, name: "autoPlay", label: "Auto Play", type: "boolean" },
    { required: false, name: "swipe", label: "Swipe", type: "boolean" },
  ],

  uiSchema: {
    linkTarget: {
      "ui:widget": "SelectWidget",
    },
    animation: {
      "ui:widget": "SelectWidget",
    },
    fullHeightHover: {
      "ui:help":
        "Defines if the the next/previous button wrappers will cover the full height of the Item element and show buttons on full height hover",
    },
    cycleNavigation: {
      "ui:help":
        "Defines if the next button will be visible on the last slide, and the previous button on the first slide. Auto-play also stops on the last slide. Indicators continue to work normally.",
    },
  },
  widgets: { SelectWidget },
}

export const BusinessAdsConfig = ({ businessId }: any) => {
  const [list, setList] = useState<any>([])

  const onAdd = useCallback(
    (name: any) =>
      async function (formData: any) {
        await addSubcollection(`businesses/${businessId}`, "ads-config", name, "ads", formData)
        await load()
        PopupService.setPopup(null)
      },
    [businessId],
  )
  async function load() {
    /*
       subCollectionDoc: "ads",
    path: ["businesses", id],
    startsWith: "config-",
    propHoldingSubCollection: 'ads-config'
      */
    const docRef = doc(db, "businesses", businessId)

    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      const rawData: any = { ...docSnap.data(), id: docSnap.id }

      setList(rawData["ads-config"] || [])
    }
  }
  useEffect(() => {
    load()
  }, [])

  return (
    <div>
      <b>
        Global Ads Config{" "}
        <Button
          onClick={() => {
            PopupService.setPopup(<AddNewConfig onAdd={onAdd} />)
          }}
        >
          <Add />
        </Button>
      </b>
      <div className="border-solid border-2 border-sky-500 p-5">
        {list.map((item: any, i: React.Key | null | undefined) => (
          <div key={i} className="flex flex-row gap-10">
            <h1>{item}</h1>
            <Button
              onClick={async () => {
                await deleteCollectionAndImages({
                  bucketPath: null,
                  collectionPath: `businesses/${businessId}/${item}`,
                  documentIds: ["ads"],
                })
                await removeSubcollectionProperty(`businesses/${businessId}`, "ads-config", item)
                await load()
              }}
            >
              <Delete />
            </Button>
            <Button
              onClick={async () => {
                const responseData = await getSubcollectionData(
                  `businesses/${businessId}`,
                  item,
                  "ads",
                )
                PopupService.setPopup(
                  <div>
                    <AddEditComponent
                      onAdd={async (formData: any) => {
                        await addSubcollection(
                          `businesses/${businessId}`,
                          "ads-config",
                          item,
                          "ads",
                          formData,
                        )
                        PopupService.setPopup(null)
                      }}
                      data={responseData}
                      mainId=""
                      Service={{ ...data }}
                    />
                  </div>,
                )
              }}
            >
              <Edit />
            </Button>
          </div>
        ))}
        {/* <DataProvider {...data}>
        {AddEditComponent}
        <AddEditComponent onAdded={() => {}} data={{}} mainId="" Service={{}} />
      </DataProvider> */}
      </div>
    </div>
  )
}
function AddNewConfig({ onAdd }: any) {
  const [newName, setNewName] = useState("")

  return (
    <div>
      <TextField label="Config Name" onChange={(event) => setNewName(event.target.value)} />
      <AddEditComponent
        onAdd={onAdd(newName)}
        onAdded={() => {}}
        data={{}}
        mainId=""
        Service={{ ...data }}
      />
    </div>
  )
}
