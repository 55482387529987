import { Button } from "@mui/material"
import CreateEdit from "./CreateEdit"
import React from "react"
import { PopupService } from "../../../react-ui-components/components/PopupService";

export function editColumn({
  imagePropName,
  path,
  setLoading,
  onEditSuccess,
  schema,
  column2object,
  dataRows,
}: any) {
  return {
    name: "Edit",
    options: {
      customHeadLabelRender: () => null,
      filter: true,
      sort: false,
      empty: true,
      customBodyRender: (
        value: any,
        tableMeta: { rowData: any; rowIndex: string | number },
        updateValue: any,
      ) => {
        return (
          <Button
            variant="text"
            style={{ marginBottom: 10 }}
            onClick={() => {
              PopupService.setPopup(
                <CreateEdit
                  imagePropName={imagePropName}
                  schema={schema}
                  path={path}
                  setLoading={setLoading}
                  data={column2object(tableMeta.rowData)}
                  title="Edit"
                  onSuccess={(result: any) => {
                    const id = dataRows ? dataRows[tableMeta.rowIndex]?.id : ""
                    onEditSuccess && onEditSuccess({ result, id })
                  }}
                />,
              )
            }}
          >
            Edit
          </Button>
        )
      },
    },
  }
}
