import { Alert, Card, CardContent, CardHeader } from "@mui/material"

import React, { useEffect, useState } from "react"
import Toolbar, { EditButton } from "../core/Toolbar"

import CustomObjectFieldTabs from "./CustomObjectFieldTabs"
import { DisableMessage } from "../../utils/DisableMessage"
import { Form, IChangeEvent } from "@rjsf/mui"
import { GlobalRafflesConfig } from "./GlobalRaffleConfig"
import { CountryLocationSelector, LocationProvider } from "../../LocationSelector"
import SplitPane from "react-split-pane"
import { auth } from "../../firebase"
import { deleteCollectionAndImages } from "../../GenericListComponent"
import { handleUpload } from "../../utils"
import { useAppConfiguration } from "../AppConfigurationService"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import validator from "@rjsf/validator-ajv8"
import { DateTimeWidget, ImageWidget } from "../CustomWidgets"
import { LoadingService } from "../../react-ui-components/components/LoadingService"
import { PopupService } from "../../react-ui-components/components/PopupService"
import { raffleSchema } from "../../react-ui-components/components/raffle/raffleSchema"
import { raffleUISchema } from "../../react-ui-components/components/raffle/raffleUISchema"
import Raffles from "../../react-ui-components/components/Raffles"
import { IRaffle } from "../../react-ui-components/components/models"

interface CountryUIWidgetProps {
  onChange: (value: any) => void
  [key: string]: any
}

function CountryUIWidget({ onChange, ...rest }: CountryUIWidgetProps) {
  function onHandleChange(_name: string, value: any) {
    onChange(value)
  }
  return (
    <LocationProvider {...rest}>
      <CountryLocationSelector {...rest} onChange={onHandleChange} />
    </LocationProvider>
  )
}

interface RaffleEditViewProps {
  raffle: IRaffle
  rafflesConfig?: any
}

const RaffleForm = ({
  raffle,
  raffleSchema,
  raffleUISchema,
  handleFormChange,
  onSubmit,
}: {
  raffle: IRaffle
  raffleSchema: any
  raffleUISchema: any
  handleFormChange: (e: IChangeEvent) => void
  onSubmit: (e: IChangeEvent) => void
}) => (
  <Card>
    <CardContent>
      <div className="h-screen overflow-auto">
        <Form
          formData={raffle}
          widgets={{ DateTimeWidget }}
          schema={{
            ...raffleSchema,
            properties: {
              ...raffleSchema.properties,
              rafflesImagesLink: { type: "string", title: "Raffles Images Link" },
              ticketDesign: {
                ...raffleSchema.properties.ticketDesign,
                properties: {
                  ...raffleSchema.properties.ticketDesign.properties,
                  ticketImage: { type: "string", title: "Ticket Image" },
                },
              },
            },
          }}
          uiSchema={{
            ...raffleUISchema,
            ticketDesign: {
              ticketImage: {
                "ui:widget": ImageWidget,
              },
            },
            country: {
              "ui:widget": CountryUIWidget,
            },
            raffleLocation: {
              country: {
                "ui:widget": CountryUIWidget,
              },
            },
          }}
          validator={validator}
          onChange={handleFormChange}
          onSubmit={((formValue: any) => {
            if (!onSubmit) { return; }

            const data = formValue.formData
            formValue.formData = {
              ...data,
              location: {

                country: '',
                city: '',
                province: '',
                ...data.location
              },

            }

            onSubmit(formValue)
          }) as any}
          templates={{
            ObjectFieldTemplate: CustomObjectFieldTabs,
          }}
          formContext={{ className: "m-5" }}
        />
      </div>
    </CardContent>
  </Card>
)

const RaffleDisplay = ({
  raffle,
  user,
  config,
}: {
  raffle: IRaffle
  user: any
  config: any
}) => (
  <Card>
    <CardContent>
      <div className="h-screen overflow-auto">
        <DisableMessage disabled={raffle.disabled} />
        <Raffles
          raffles={[{ ...raffle, disabled: false }]}
          fetchLikes={() => { }}
          user={user}
          handleLike={async (raffleId: string) => { }}
          liked={true}
          likeCount={100}
          loading={false}
          likeLoading={false}
          rafflesConfig={config}
          termsIconProps={{
            src: require("../../images/termsIcon.png"),
            style: { height: 30 },
          }}
        />
      </div>
    </CardContent>
  </Card>
)

export function RaffleEditView({
  raffle: raffleData,
  rafflesConfig,
}: RaffleEditViewProps) {
  raffleData = raffleData || {}
  const [isEditing, setIsEditing] = useState(false)
  const handleEditClick = () => setIsEditing(!isEditing)
  const navigate = useNavigate()
  const [user] = useAuthState(auth)

  const [raffle, setRaffle] = useState(raffleData)
  const { configuration } = useAppConfiguration()
  const [config, setConfig] = useState(configuration.rafflesScreen)
  const { saveRaffle, disableRaffle, duplicateRaffle } = useAppConfiguration()

  useEffect(() => {
    setConfig(rafflesConfig || configuration.rafflesScreen)
  }, [rafflesConfig])

  useEffect(() => {
    setRaffle(raffleData)
  }, [raffleData])

  const handleFormChange = ({ formData }: IChangeEvent) => {
    setRaffle(formData)
  }

  const onSubmit = async ({ formData }: IChangeEvent) => {
    try {
      LoadingService.show()
      if (formData.raffleImages?.length > 0) {
        const imagesURLs = await Promise.all(
          formData.raffleImages.map((image: string, index: number) => {
            return handleUpload({
              image,
              id: raffle.id,
              fieldName: "assets",
              imagesBucket: "raffles",
              prevValue: raffleData.raffleImages?.[index],
            })
          }),
        )
        formData.raffleImages = imagesURLs
      }

      const data = await saveRaffle(formData)

      setRaffle(data)
    } catch (error: any) {
      console.log("Error uploading raffles images: ", formData.raffleImages)
      PopupService.pushPopup(error.message)
    } finally {
      LoadingService.hide()
    }
  }

  const onDuplicate = async () => {
    try {
      LoadingService.show()

      const result = await duplicateRaffle(raffleData)
      navigate(`/raffles/${result.id}`)
    } catch (error: any) {
      console.log("Error duplicating raffle: ", raffleData.raffleImages)
      PopupService.pushPopup(error.message)
    } finally {
      LoadingService.hide()
    }
  }

  return (
    <div>
      <div className="flex flex-row justify-between gap-5 m-2">
        <h1>
          <strong>Raffle ID: </strong>
          {raffle.id}
        </h1>
        <h1>
          <strong>Raffle Name: </strong>
          {raffle.name}
        </h1>
      </div>
      <Card>
        <CardHeader
          avatar={
            <Toolbar
              disabled={raffle.disabled}
              onDelete={async function (): void {
                PopupService.setConfirm(
                  "Are you sure you want to delete this raffle?",
                  async (answer) => {
                    if (/ok/i.test(answer)) {
                      try {
                        LoadingService.setLoading(true)

                        await deleteCollectionAndImages({
                          bucketPath: "rafflesImages",
                          collectionPath: "raffles",
                          documentIds: [raffleData.id],
                        })
                        navigate("/raffles")
                        console.log("Collection and images deleted successfully.")
                      } catch (error: any) {
                        const msg = "Error deleting collection and images"
                        LoadingService.setLoading(true, msg, true)
                        console.error(msg, error)
                        PopupService.pushPopup(`${msg}\n${error.message}`)
                      } finally {
                        LoadingService.setLoading(false)
                      }
                    }
                  },
                )
              }}
              onDuplicate={onDuplicate}
              onDisable={async function (): void {
                try {
                  LoadingService.show()
                  const disabled = !raffle.disabled
                  await disableRaffle(raffle.id, disabled)
                  setRaffle({ ...raffle, disabled })
                } catch (error: any) {
                  PopupService.pushPopup(error.message)
                } finally {
                  LoadingService.hide()
                }
              }}
            />
          }
          action={<EditButton onEdit={handleEditClick} />}
        />

        <CardContent className="h-screen overflow-auto">
          <GlobalRafflesConfig
            onChange={(formData) => {
              setConfig(formData)
            }}
          />

          <SplitPane
            split="vertical"
            minSize={200}
            defaultSize={isEditing ? "50%" : "100%"}
            className="overflow-auto"
          >
            {isEditing ? (
              <RaffleForm
                raffle={raffle}
                raffleSchema={raffleSchema}
                raffleUISchema={raffleUISchema}
                handleFormChange={handleFormChange}
                onSubmit={onSubmit}
              />
            ) : null}

            <RaffleDisplay raffle={raffle} user={user} config={config} />
          </SplitPane>
        </CardContent>
      </Card>
    </div>
  )
}