import * as htmlToImage from "html-to-image"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { collection, getDocs, query, where } from "firebase/firestore"
import Form from "@rjsf/material-ui"
import { jsPDF } from "jspdf"
import { useNavigate } from "react-router-dom"
import validator from "@rjsf/validator-ajv8"
import htmr from "htmr"
import { colors } from "../utils/colors"
import { db } from "../firebase"

const Tickets = () => {
  const [generatedTickets, setGeneratedTickets] = useState<any>([])
  const [generatedRaffles, setGeneratedRaffles] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [selectedRaffle, setSelectedRaffle] = useState<any>(null)
  const [formData, setFormData] = useState<any>()
  const [pageContent, setPageContent] = useState<any>([])
  const [loading3, setLoading3] = useState(false)

  const navigate = useNavigate()

  const uiSchema: any = {}

  const schema = (arr: any[]) => ({
    title: "Tickets",
    type: "object",
    required: ["ticketWidth", "ticketHeight", "raffle", "pageHeight", "pageWidth"],
    properties: {
      textColor: { type: "string", title: "Text Color" },
      backgroundColor: { type: "string", title: "Background Color" },
      ticketWidth: { type: "number", title: "Ticket Width" },
      ticketHeight: { type: "number", title: "Ticket Height" },
      pageWidth: { type: "number", title: "Page Width" },
      pageHeight: { type: "number", title: "Page Height" },
      raffle: {
        title: "Select Raffle",
        uniqueItems: true,
        enumNames: arr?.map((x: { title: any }) => x.title),
        enum: arr,
      },
    },
  })

  async function loadRaffles() {
    setLoading(true)
    const querySnapshot = await getDocs(
      query(collection(db, "raffles"), where("disabled", "==", false)),
    )

    const raffles = querySnapshot.docs.map((raffleDoc) => {
      const raffle = raffleDoc.data()
      return {
        title: raffle.title ? htmr(raffle.title) : "",
        type: "string",
        id: raffle.id,
        name: raffle.name ? htmr(raffle.name) : "",
      }
    })
    setGeneratedRaffles(raffles)
    setLoading(false)
  }

  useEffect(() => {
    loadRaffles()
  }, [])

  if (loading) {
    return <h1>Loading...</h1>
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <a
        target="_blank"
        href="https://pixelsconverter.com/us-paper-sizes-to-pixels"
        rel="noreferrer"
      >
        Page Sizes
      </a>
      <i>
        If you don't provide ticket width or height it will use the original designed width and
        height
      </i>
      <Form
        formData={{
          raffle: selectedRaffle,
          ticketWidth: 0,
          ticketHeight: 0,
          pageWidth: 816,
          pageHeight: 1054,
        }}
        schema={schema(generatedRaffles) as any}
        uiSchema={uiSchema}
        validator={validator}
        onChange={console.log}
        onSubmit={async (result) => {
          if (result?.errors?.length) return

          const data = result.formData
          const raffle = data.raffle
          setFormData(data)
          setSelectedRaffle(raffle)

          setLoading2(true)

          try {
            const ticketsRef = await getDocs(collection(db, "raffles", raffle.id, "tickets"))
            const allTickets = ticketsRef.docs.map((ticketDoc) => ticketDoc.data())
            setGeneratedTickets(allTickets)

            const height = data.ticketHeight || allTickets[0]?.design?.height
            const width = data.ticketWidth || allTickets[0]?.design?.width
            const perPage = Math.floor(
              Math.floor(data.pageHeight / height) * Math.floor(data.pageWidth / width),
            )
            const pages = Math.ceil(allTickets.length / perPage)

            const allPages = Array.from({ length: pages }, (_, index) => (
              <div
                id={`tickets-items-${index}`}
                style={{
                  margin: 10,
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  width: data.pageWidth || 816,
                }}
              >
                {allTickets
                  .slice(index * perPage, (index + 1) * perPage)
                  .map((item: any, i: any) => (
                    <TicketItem
                      key={`${item.id}-${i}`}
                      item={{
                        ...item,
                        design: {
                          ...item.design,
                          width: data.ticketWidth || item.design.width,
                          height: data.ticketHeight || item.design.height,
                          color: data.backgroundColor || item.design.backgroundColor,
                          textColor: data.textColor || item.design.color,
                        },
                      }}
                    />
                  ))}
              </div>
            ))
            setPageContent(allPages)
          } catch (error) {
            console.error("ERROR getting tickets--->", error)
          } finally {
            setLoading2(false)
          }
        }}
        onError={console.error}
      />

      {!loading3 ? null : <h1>Generating pdf...</h1>}
      {loading2 ? (
        <h1>Loading...</h1>
      ) : (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <button
            onClick={async () => {
              setLoading3(true)
              try {
                const pdf = new jsPDF({ unit: "px", format: "letter", userUnit: "px" } as any)

                for (let index = 0; index < pageContent.length; index++) {
                  const input = document.getElementById(`tickets-items-${index}`)
                  const dataUrl = await htmlToImage.toPng(input as any, { quality: 0.95 })
                  const pdfWidth = pdf.internal.pageSize.getWidth()
                  const imgProps = pdf.getImageProperties(dataUrl)
                  const pdfHeight =
                    (imgProps.height * pdfWidth) / imgProps.width + (formData?.pageVMargin + 20) ||
                    0
                  pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight)
                  if (index < pageContent.length - 1) pdf.addPage()
                }

                pdf.save(`${(selectedRaffle?.name || "raffle").replace(/\s/gi, "_")}.pdf`)
              } catch (error) {
                console.error("There was an error generating the pdf.", error)
              } finally {
                setLoading3(false)
              }
            }}
          >
            Download
          </button>

          {pageContent}
        </div>
      )}
    </div>
  )
}

export default Tickets

export const getLuxonDate = (date: any, isSeconds = false): any => {
  if (!date) {
    return console.log(`Invalid date (${date}).`)
  }
  const date_ = isSeconds
    ? DateTime.fromMillis(date).isValid
      ? DateTime.fromMillis(date)
      : DateTime.fromSeconds(date)
    : DateTime.fromISO(date).isValid
    ? DateTime.fromISO(date)
    : DateTime.fromJSDate(date)
  return date_
}

function getStyle(style: any[]) {
  return Array.isArray(style)
    ? style.reduce((c, n) => ({ ...c, ...n, display: "flex" }), {})
    : style
}

export function TicketItem({ item }: { item?: any }) {
  const [design, setDesign] = useState({ ...item?.design })

  useEffect(() => {
    setDesign({ ...item?.design })
  }, [item?.design])

  return (
    <div
      style={{
        width: design.width,
        height: design.height,
        color: design.textColor,
      }}
    >
      <div
        style={{
          backgroundImage: `url(${design.image || require("./ticket-img-no-name.png")})`,
          backgroundRepeat: "no-repeat",
          padding: design.padding,
          backgroundSize: "97% 100%",
        }}
      >
        <div
          style={{
            ...$root(design.color),
            flexDirection: "column",
            ...$innerRoot(design),
            overflow: "hidden",
          }}
        >
          <Row
            hide={!item.code}
            textColor={design.textColor}
            labelTx="Code"
            value={item.code}
            style={$row}
            size="xs"
          />

          {item.expirationDate && (
            <Row
              textColor={design.textColor}
              labelTx="Date"
              value={getLuxonDate(+item.expirationDate, true).toFormat("D")}
              style={$row}
            />
          )}
          {item.raffleType ? (
            <Row
              textColor={design.textColor}
              numberOfLines={2}
              labelTx="Raffle"
              value={item.raffleType ? htmr(item.raffleType) : ""}
              style={$row}
              valueSize="xxs"
            />
          ) : (
            <Text text="TICKET" size="xxl" style={$empty(design.textColor)} />
          )}
        </div>
      </div>
    </div>
  )
}

const $row = { display: "flex", alignSelf: "flex-start" }
const $root = (backgroundColor: any) => ({
  display: "flex",
  borderColor: "#000",
  borderWidth: 2,
  borderRadius: 10,
  padding: 2,
  backgroundColor: backgroundColor || colors.palette.neutral100,
  width: "100%",
  justifyContent: "flex-start",
  borderStyle: "solid",
})
const $innerRoot = (design: { width: number; padding: number; height: number }) => ({
  display: "flex",
  padding: 5,
  width: design.width - design.padding - 10,
  height: design.height - design.padding - 10,
})
const $empty = (textColor: any) => ({ display: "flex", alignSelf: "center", color: textColor })

const Text = ({ text, size, style }: any) => (
  <p style={{ ...getStyle(style), fontSize: size === "xxl" ? 36 : 14 }}>{text}</p>
)
const Row = ({ labelTx, value, textColor, style }: any) => (
  <div style={{ ...style, color: textColor }}>
    <p>
      <b>{labelTx}</b>: {value}
    </p>
  </div>
)
