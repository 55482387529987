import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { formatCash, getCountryInfo } from './utils';

import Carousel from 'react-material-ui-carousel';
import { EmptyComponent } from './EmptyComponent';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import { IRaffle } from './models/IRaffle';
import { IRaffleConfig } from './models/IRaffleConfig';
import { LikeComponent } from './LikeComponent';
import { PopupService } from './PopupService';
import TermsIcon from './TermsIcon';
import htmr from 'htmr';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const UnorderedList = styled.ul`
  list-style: disc !important;
  margin: 0 16px !important;
  padding-left: 40px !important;
`;

const transform = {
  ul: UnorderedList,
  h1: styled.h1`
    font-weight: 900 !important;
    text-align: center !important;
    font-size: xx-large !important;
  `,
  h2: styled.h2`
    font-weight: 800 !important;
    text-align: center !important;
    font-size: x-large !important;
  `,
  h3: styled.h3`
    font-weight: 700 !important;
    text-align: center !important;
    font-size: larger !important;
  `,
  h4: styled.h4`
    font-weight: 600 !important;
    text-align: center !important;
    font-size: large !important;
  `,
  h5: styled.h5`
    font-weight: 500 !important;
    text-align: center !important;
    font-size: medium !important;
  `,
  h6: styled.h6`
    font-weight: 400 !important;
    text-align: center !important;
    font-size: small !important;
  `,
};

/* The `Raffles` component in the provided code snippet is a functional component in React that takes
several props and renders a list of raffles with associated information. Here's a breakdown of what
the component does: */

const Raffles = ({
  handleLike,
  user,
  getLikesRef,
  raffles: rafflesList,
  rafflesConfig = {
    descriptionLength: 50,
    hideAllActions: true,
    imageResizeMode: 'contain',
    rafflesImageHeight: 500,
  },
  termsIconProps,
}: {
  raffles: IRaffle[];
  fetchLikes: any;
  user: any;
  handleLike: (raffleId: string) => Promise<any>;
  getLikesRef: any;
  rafflesConfig: IRaffleConfig;
  termsIconProps: any;
}) => {
  const { t } = useTranslation();
  const [raffles, setRaffles] = useState<IRaffle[]>([]);

  useEffect(() => {
    setRaffles(rafflesList?.filter?.((x) => !x.disabled) || []);
  }, [rafflesList]);

  if (!raffles?.length) {
    return <EmptyComponent message={t('No Raffles Available At The Moment')} />;
  }

  return (
    <div
      style={{
        backgroundColor: rafflesConfig.backgroundColor || undefined,
      }}
    >
      {raffles.map((raffle: any, i: number) => (
        <RaffleItem
          key={`${raffle.name}-${i}`}
          raffle={raffle}
          user={user}
          getLikesRef={getLikesRef}
          handleLike={handleLike}
          rafflesConfig={rafflesConfig}
          termsIconProps={termsIconProps}
        />
      ))}
    </div>
  );
};

// function Like(props: {
//   handleLike: (raffleId: string) => Promise<void>;
//   raffle: any;
//   fetchLikes: Function;
//   liked: any;
//   likeCount: number;
//   loading: boolean;
// }) {
//   const { handleLike, raffle, liked, likeCount, loading } = props;

//   return (
//     <Stack direction='row' spacing={0.1} alignItems='center'>
//       {/* <Typography variant="caption">{t("like")}</Typography> */}

//       <IconButton onClick={loading ? undefined : () => handleLike(raffle.id)}>
//         {loading ? (
//           <CircularProgress size={24} />
//         ) : liked ? (
//           <Favorite color='error' />
//         ) : (
//           <FavoriteBorder />
//         )}
//       </IconButton>

//       <Typography variant='body2'>{formatCash(likeCount)}</Typography>
//     </Stack>
//   );
// }

export default Raffles;

const emojiCache: any = {};
export const RaffleItem: React.FC<{
  raffle: IRaffle;
  rafflesConfig: any;
  likeLoading: boolean;
  termsIconProps: any;
  user: any;
  handleLike: (raffleId: string) => Promise<any>;
  getLikesRef: any;
}> = ({ getLikesRef, raffle, handleLike, rafflesConfig, termsIconProps, user }) => {
  const { t } = useTranslation();
  const imageHeight = rafflesConfig.rafflesImageHeight || '100%';
  const [emoji, setEmoji] = useState('🌍');

  useEffect(() => {
    const country = raffle.location?.country;
    const ej = country ? getCountryInfo(country)?.emoji : '🌍';
    if (ej) {
      setEmoji(ej);
    }
  }, [raffle]);

  return (
    <div
      onDoubleClick={() => handleLike(raffle.id)}
      style={{
        borderWidth: raffle.borderWidth || 1,
        borderColor: raffle.borderColor || '#DDD',
        borderStyle: raffle.borderStyle || 'solid',
        marginTop: raffle.marginTop || 10,
        marginBottom: raffle.marginBottom || 10,
        padding: raffle.padding || 10,
      }}
    >
      {raffle.type === 'Image' ? null : (
        <div>
          {/* imageResizeMode:{rafflesConfig.imageResizeMode} */}
          {raffle.title && (
            <Typography variant='h4'>{htmr(raffle.title)}</Typography>
          )}
          {raffle.subtitle && (
            <Typography variant='h5'>{htmr(raffle.subtitle)}</Typography>
          )}
          {raffle.description && (
            <Typography variant='h6'>{htmr(raffle.description)}</Typography>
          )}
        </div>
      )}
      {!raffle.raffleImages?.length ? null : (
        <Carousel
          cycleNavigation
          fullHeightHover
          indicators
          navButtonsAlwaysVisible={!!raffle.navButtonsAlwaysVisible}
          height={raffle.height || rafflesConfig.rafflesImageHeight || 400}
          stopAutoPlayOnHover={!!raffle.stopAutoPlayOnHover}
          autoPlay={raffle.autoPlay || rafflesConfig.autoPlay || true}
          animation={raffle.animation || 'slide'}
          duration={
            raffle.animationDuration || rafflesConfig.animationDuration || true
          }
          swipe
          interval={raffle.playInterval || rafflesConfig.playInterval || 4000}
        >
          {raffle.raffleImages.map((item: string, i: number) => (
            <div
              key={i}
              style={{
                // borderColor: "red",
                // borderWidth: 1,
                // borderStyle: "double",

                height: imageHeight,
              }}
              className='flex justify-center'
            >
              <img
                src={item}
                style={{
                  height: imageHeight,
                  objectFit:
                    raffle.imageResizeMode ||
                    rafflesConfig.imageResizeMode ||
                    'contain', //fill | contain | cover | none | scale-down
                }}
              />
            </div>
          ))}
        </Carousel>
      )}
      {raffle.type === 'Image' ? null : (
        <div className='flex flex-row gap-5'>
          <p>{emoji}</p>
          <Typography>{new Date(raffle.raffleDate).toString()}</Typography>
        </div>
      )}
      {raffle.type === 'Image' ||
      (typeof raffle.hideAllActions === 'undefined'
        ? false
        : raffle.hideAllActions) ? null : (
        <div className='flex row gap-5 space-between justify-center align-center items-center'>
          <LikeComponent
            raffle={raffle}
            user={user}
            getLikesRef={getLikesRef}
            handleLike={handleLike}
          />
          <Button
            startIcon={<TermsIcon {...termsIconProps} />}
            variant='contained'
            color='primary'
            fullWidth
            onClick={() => {
              PopupService.pushPopup(
                htmr(t(raffle.termsText || 'No terms available yet'), {
                  transform,
                })
              );
            }}
          >
            {t('terms')}
          </Button>
        </div>
      )}
      {/* {process.env.NODE_ENV === 'development' ? (
        <Typography variant='h4' className='text-red'>
          Country: {raffle.location?.country}
        </Typography>
      ) : null} */}
    </div>
  );
};
