import { Box, CardContent, FormLabel, Stack } from "@mui/material"
import React, { useState } from "react"

import { Card } from "react-bootstrap"
import { Form } from "@rjsf/mui"
import { IChangeEvent } from "@rjsf/core"
import { RJSFSchema } from "@rjsf/utils"
import { useAppConfiguration } from "../AppConfigurationService"
import validator from "@rjsf/validator-ajv8"
import { LoadingService } from "../../react-ui-components/components/LoadingService"
import { PopupService } from "../../react-ui-components/components/PopupService"
import { raffleSchemaConfig } from "../../react-ui-components/components/raffle/raffleSchemaConfig"
import { CustomObjectFieldTemplateCall } from "../../react-ui-components/components/utils/CustomObjectFieldTemplate"

const uiSchema = {
  // "ui:field": "horizontalLayout",
}

export const HorizontalGrid = ({
  schema,
  uiSchema,
  formData,
  registry,
  required,
  idSchema,
  onChange,
  onFocus,
  onBlur,
}: any) => {
  const { fields } = registry
  const { SchemaField } = fields
  const [localFormData, setLocalFormData] = useState(formData || {})

  const handleFieldChange = (key) => (value) => {
    const updatedFormData = { ...localFormData, [key]: value }
    setLocalFormData(updatedFormData) // Update local state
    onChange(updatedFormData) // Emit updated formData to parent
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      flexWrap="wrap"
      sx={{ rowGap: 2 }} // Adds spacing between rows when wrapping
    >
      {Object.keys(schema.properties || {}).map((key) => (
        <Box
          key={key}
          sx={{
            minWidth: "150px", // Minimum width to ensure good wrapping behavior
          }}
        >
          <SchemaField
            name={key}
            schema={schema.properties[key]}
            uiSchema={uiSchema[key] || {}}
            formData={formData[key]}
            required={required && required.includes(key)}
            registry={registry}
            idSchema={idSchema[key]}
            onChange={handleFieldChange(key)}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </Box>
      ))}
    </Stack>
  )
}

const fields = {
  //   horizontalLayout: HorizontalGrid,
}

export const GlobalRafflesConfig = ({ onChange }: any) => {
  const { configuration, saveConfig } = useAppConfiguration()

  const [formData, setFormData] = useState<any>(configuration)
  const [rootExpanded, setRootExpanded] = useState(false)

  const handleFormChange = async (
    result: IChangeEvent<any, RJSFSchema, any>,
    id?: string | undefined,
  ) => {
    const data = result.formData
    setFormData(data)
    onChange(data)
  }

  const onSubmit = async (
    result: IChangeEvent<any, RJSFSchema, any>,
    event: React.FormEvent<any>,
  ) => {
    LoadingService.setLoading(true)
    try {
      await saveConfig(result.formData)
    } catch (error: any) {
      PopupService.pushPopup(error.message)
    } finally {
      LoadingService.setLoading(false)
    }
  }

  return (
    <Card>
      <CardContent>
        <div className="overflow-auto">
          <FormLabel>Global Raffle Config</FormLabel>
          <Form
            formData={formData}
            // widgets={widgets || {}}
            schema={raffleSchemaConfig}
            uiSchema={uiSchema}
            fields={fields}
            validator={validator}
            onChange={handleFormChange}
            onSubmit={onSubmit}
            // onError={(error) => onError && onError(error)}
            templates={{
              ObjectFieldTemplate: CustomObjectFieldTemplateCall({
                className: "flex flex-row gap-5 *:min-w-96 flex-wrap",
                toggleRootOnly: true,
                rootExpanded,
                setRootExpanded,
              }),
              //   FieldTemplate: CustomFieldTemplate,
            }}
            // formContext={formContext}
            formContext={{ className: "horizontal-form" }}
          />
        </div>
      </CardContent>
    </Card>
  )
}
