import { Button, FormControlLabel, Switch } from "@mui/material"

import CreateEdit from "../core/CreateEditOptions/CreateEdit"
import React from "react"
import { column2object } from "./column2object"
import { schema } from "./schema"
import { PopupService } from "../../react-ui-components/components/PopupService"

export function getColumns({ setLoading, setData, path, onEditSuccess, imagePropName }: any) {
  return [
    {
      name: "path",
      options: {
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Edit",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value: any, tableMeta: { rowData: any }, updateValue: any) => {
          return (
            <Button
              variant="text"
              style={{ marginBottom: 10 }}
              onClick={() =>
                PopupService.setPopup(({ close }: any) => (
                  <CreateEdit
                    imagePropName={imagePropName}
                    schema={schema}
                    path={path}
                    setLoading={setLoading}
                    data={column2object(tableMeta.rowData)}
                    title="Edit"
                    onSuccess={(result: any) => {
                      onEditSuccess && onEditSuccess({ result, close })
                    }}
                  />
                ))
              }
            >
              Edit
            </Button>
          )
        },
      },
    },
    {
      name: "disabled",
      options: {
        filter: false,
        customBodyRender: (value: boolean | undefined, tableMeta: any, updateValue: any) => {
          return (
            <FormControlLabel
              label={value ? "Yes" : "No"}
              value={value ? "Yes" : "No"}
              control={
                <Switch disabled color="primary" checked={value} value={value ? "Yes" : "No"} />
              }
              onChange={(event: any) => {
                const val = event.target.value
                // TODO: to be updated later
                // console.log({value, tableMeta, event});
                // const key = column2object(tableMeta.rowData).key;
                // updateData({
                //   setLoading,
                //   data: {[key]: {disabled: val}},
                //   path,
                //   onSuccess: () => getData(setData, path),
                // });
                // updateValue(val === 'Yes' ? false : true);
              }}
            />
          )
        },
      },
    },
    "key",
    "textContent",
    "image",
    {
      name: "order",
      options: {
        sort: true,
      },
    },
  ]
}
