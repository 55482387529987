import { AddEditComponent, DataProvider, ListComponent } from "./GenericListComponent"
import {
  AppBar,
  Avatar,
  Box,
  Button,
  FormLabel,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import { Link, useLocation, useParams, useRouteLoaderData } from "react-router-dom"
import { useEffect, useState } from "react"

import { BusinessAdsComponent } from "./BusinessAdsComponent"
import { BusinessNotificationsComponent } from "./BusinessNotificationsComponent"
import { BusinessOffersComponent } from "./BusinessOfferComponent"
import { Edit } from "@mui/icons-material"
import { ImageWidget } from "./components/CustomWidgets"
import React from "react"
import { SampleImageRenderer } from "./SampleImageRenderer"
import SwipeableViews from "react-swipeable-views"
import { getData } from "./components/core/CreateEditOptions/getData"
import { PopupService } from "./react-ui-components/components/PopupService"

const configData = {
  getListLocation(id: any) {
    return ["businesses"]
  },
  EDIT_TITLE: "Edit Business",
  ADD_TITLE: "Add Business",
  LOADING_MESSAGE: "Loading Business...",
  TITLE: "Businesses",
  IMAGES_BUCKET: "businessesImages",

  columnsProperties: [
    { hidden: true, name: "id", label: "Id" },
    { required: false, name: "disabled", label: "Disabled", type: "boolean" },

    { required: true, name: "name", label: "Name" },
    { required: true, name: "order", label: "Order" },

    { required: true, name: "phone", label: "Phone" },
    { required: true, name: "email", label: "Email" },
    { required: true, name: "description", label: "Description" },
    { required: false, name: "websiteIPs", label: "Website IPs" },
    {
      hidden: false,
      required: false,
      name: "icon",
      options: {
        customBodyRender: SampleImageRenderer(),
      },
      label: "Icon",
    },

    {
      hidden: false,
      type: "array",
      required: false,
      name: "storefrontImages",
      label: "Storefront Images",
      options: {
        customBodyRender: SampleImageRenderer(),
      },
      items: {
        type: "string",
      },
    },
    {
      hidden: false,
      type: "array",
      required: false,
      name: "insideImages",
      label: "Inside Images",
      options: {
        customBodyRender: SampleImageRenderer(),
      },
      items: {
        type: "string",
      },
    },
    { required: true, name: "language", label: "Language" },
    { required: true, name: "country", label: "Country" },
    { required: false, name: "state", label: "State" },
    { required: false, name: "city", label: "City" },
    { required: false, name: "street", label: "Street" },
    { required: false, name: "county", label: "County" },
    { required: false, name: "zipCode", label: "Zip Code" },
  ],

  uiSchema: {
    icon: {
      "ui:widget": ImageWidget,
    },

    description: {
      "ui:widget": "textarea",
    },
    disabled: {
      "ui:widget": "radio",
    },
    storefrontImages: {
      items: {
        "ui:widget": ImageWidget,
      },
    },
    insideImages: {
      items: {
        "ui:widget": ImageWidget,
      },
    },
  },

  async uploadStorefrontImages(
    formData: { storefrontImages: any[] },
    handleUpload: (arg0: any, arg1: string, arg2: any) => any,
    id: any,
  ) {
    if (formData?.storefrontImages?.length > 0) {
      const imagesURLs = await Promise.all(
        formData.storefrontImages
          .map((image: any) => {
            return handleUpload(id, this.IMAGES_BUCKET, image)
          })
          .filter((x: any) => !!x),
      )
      formData.storefrontImages = imagesURLs
    }
    return formData
  },
  async uploadInsideImages(
    formData: { insideImages: any[] },
    handleUpload: (arg0: any, arg1: string, arg2: any) => any,
    id: any,
  ) {
    if (formData?.insideImages?.length > 0) {
      const imagesURLs = await Promise.all(
        formData.insideImages
          .map((image: any) => {
            return handleUpload(id, this.IMAGES_BUCKET, image)
          })
          .filter((x: any) => !!x),
      )
      formData.insideImages = imagesURLs
    }
    return formData
  },
  async uploadIconImage(
    formData: { icon: any },
    handleUpload: (arg0: any, arg1: string, arg2: any) => any,
    id: any,
  ) {
    if (formData.icon) {
      const icon = await handleUpload(id, this.IMAGES_BUCKET, formData.icon)
      formData.icon = icon
    }
    return formData
  },
  async imageProcessor(formData: any, handleUpload: any, id: any) {
    formData = await this.uploadStorefrontImages(formData, handleUpload, id)
    formData = await this.uploadInsideImages(formData, handleUpload, id)
    formData = await this.uploadIconImage(formData, handleUpload, id)

    return formData
  },
  onRowClick: (
    list: { [x: string]: any },
    navigate: (arg0: string, arg1: { state: any }) => void,
  ) => {
    return (_rowData: any, rowMeta: { dataIndex: string | number }) => {
      const state = list[rowMeta.dataIndex]
      const id = state.id
      navigate(`/businesses/${id}`, { state })
    }
  },
}

export const BusinessesComponent = () => {
  return (
    <DataProvider {...configData}>
      <ListComponent />
    </DataProvider>
  )
}

export const BusinessDetail = (props: any) => {
  const { id } = useParams()
  const { state } = useLocation()
  const [data, setData] = useState()
  const [value, setValue] = useState(0)
  const theme = useTheme()

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: React.SetStateAction<number>) => {
    setValue(index)
  }
  function reload() {
    getData({ path: `/businesses/${id}`, setData })
  }
  useEffect(() => {
    if (state?.id) {
      setData(state)
    } else {
      reload()
    }
  }, [id, state])

  return (
    <Box sx={{ bgcolor: "background.paper", width: "80vw" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Business Detail" {...a11yProps(0)} />
          <Tab label="Business Ads" {...a11yProps(1)} />
          <Tab label="Business Offers" {...a11yProps(2)} />
          <Tab label="Business Notifications" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <BusinessDetailInfo id={id} data={data} reload={reload} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <BusinessAdsComponent businessId={id} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <BusinessOffersComponent businessId={id} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <BusinessNotificationsComponent businessId={id} />
        </TabPanel>
      </SwipeableViews>
    </Box>
  )
}
function BusinessDetailInfo({ id, data, reload }: any) {
  return (
    <div>
      <div className="flex flex-row gap-6 align-center">
        <h1>Business Detail</h1>
        <Button
          onClick={() => {
            PopupService.setPopup(
              <AddEditComponent
                Service={configData}
                mainId={id}
                data={data}
                onAdded={() => {
                  reload && reload()
                }}
              />,
            )
          }}
        >
          <Edit />
        </Button>
      </div>
      <div className="flex flex-col flex-wrap gap-3 border-solid border-2 border-sky-500  w-fit">
        {!!data && (
          <div className="flex flex-row gap-4">
            <div>
              {Object.keys(data)
                .slice(0, Math.ceil(Object.keys(data).length / 2))
                .map((item) => (
                  <div key={item} className="flex flex-row flex-wrap gap-3 m-2">
                    <h1 className="capitalize w-fit">
                      {item}
                      {": "}
                    </h1>
                    <h6 className="flex flex-wrap max-w-60 break-all">
                      {JSON.stringify(data[item.trim()])}
                    </h6>
                  </div>
                ))}
            </div>

            <div>
              {Object.keys(data)
                .slice(Math.ceil(Object.keys(data).length / 2))
                .map((item) => (
                  <div key={item} className="flex flex-row flex-wrap gap-3 m-2">
                    <h1 className="capitalize w-fit">
                      {item}
                      {": "}
                    </h1>
                    <h6 className="flex flex-wrap max-w-60 break-all">
                      {JSON.stringify(data[item.trim()])}
                    </h6>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}
