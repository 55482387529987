import { FormLabel } from "@mui/material";
import { useEffect, useState } from "react";

import CustomObjectFieldTabs from "../raffle/CustomObjectFieldTabs"
import { Form } from "@rjsf/mui"
import { useAppConfiguration } from "../AppConfigurationService"
import validator from "@rjsf/validator-ajv8"
import React from "react";
import { LoadingService } from "../../react-ui-components/components/LoadingService";
import { PopupService } from "../../react-ui-components/components/PopupService";
import { imageResizeOptions } from "../../react-ui-components/components/raffle/raffleSchemaConfig";
import Editor from "../../react-ui-components/utils/customQuillModules";
import { string, array, number, object, boolean } from "../../react-ui-components/utils/schema";
import { DateTimeWidget } from "../CustomWidgets";

// function CountryUIWidget({ onChange, ...rest }: any) {
//   function onHandleChange(_name: string, value: any) {
//     onChange(value)
//   }
//   return (
//     <LocationProvider {...rest}>
//       <CountryLocationSelector {...rest} onChange={onHandleChange} />
//     </LocationProvider>
//   )
// }

const schema = {
  type: "object",
  properties: {
    addedPointsAnimation: string("Added Points Animation"),
    dateTimeFormat: string("Raffle DateTime Format", { enum: ["DATETIME_FULL"] }),
    defaultProfileUrl: string("Default Profile Url"),
    imageResizeMode: string("Image Resize Mode", {
      enum: imageResizeOptions,
    }),
    pointAnimationLink: string("Points Animation Link"),
    qrCodeIcon: string("QR Code Icon"),
    raffleTypes: array("Raffle Types", { items: { type: "string" } }),
    rafflesImageHeight: number("Raffles Image Height"),
    rafflesScreen: object("Raffles Screen", {
      descriptionLength: number("Description Length"),
      hideAllActions: boolean("Hide All Actions"),
      imageResizeMode: string("Image Resize Mode", {
        enum: imageResizeOptions,
      }),
      rafflesImageHeight: number("Raffles Image Height"),
    }),
    rafflesSectionImageHeight: number("Raffles Section Image Height"),
    sponsorsTitle: string("Sponsors Title"),
    sponsorsTitleKey: string("Sponsors Title Key"),
    termIcon: string("Term Icon"),
    vendorPercentage: number("vendorPercentage"),
    copyrightMessage: string("Copyright Message"),
    displayDownload: boolean("Display Download"),
  },
}

const uiSchema = {
  copyrightMessage: {
    "ui:widget": ({ onChange, label, ...rest }: any) => (
      <div>
        <FormLabel>{label}</FormLabel>
        <Editor {...rest} onChange={(value: string) => onChange(value)} />
      </div>
    ),
  },
}

export function DisplayContentConfig() {
  const { saveConfig, configuration } = useAppConfiguration()

  const [configData, setConfigData] = useState(configuration)

  useEffect(() => {
    setConfigData(configuration)
  }, [configuration])

  const onSubmit = async ({ formData }) => {
    try {
      LoadingService.show()
      if (!formData) {
        return "No data to save"
      }
      await saveConfig(formData)
    } catch (error: any) {
      console.log("Error saving configuration: ", formData)
      PopupService.pushPopup(error.message)
    } finally {
      LoadingService.hide()
    }
  }

  return (
    <Form
      formData={configData}
      // fields={{ LocationProvider }}
      widgets={{ DateTimeWidget }}
      schema={schema}
      uiSchema={uiSchema}
      validator={validator}
      onSubmit={onSubmit}
      // onError={(error) => onError && onError(error)}
      templates={{
        ObjectFieldTemplate: CustomObjectFieldTabs,
      }}
      formContext={{ className: "m-5" }}
    />
  )
}
