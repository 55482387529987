import { collection, doc, getDoc, getDocs } from "firebase/firestore"

import { db } from "../../../firebase"
import { LoadingService } from "../../../react-ui-components/components/LoadingService"

const get = async ({ key, setData, map, path, isCollection, setLoading, filterFunction }: any) => {
  setLoading && setLoading(true)
  LoadingService.setLoading(true, key)
  try {
    if (isCollection) {
      const querySnapshot = await getDocs(
        collection(db, typeof path === "string" ? path : path.split("/")),
      )

      const arr: any[] = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data()
        let addRecord = true
        if (filterFunction && filterFunction(data)) {
          addRecord = false
        }
        if (addRecord) {
          arr.push({ ...doc.data(), id: doc.id })
        }
      })
      setData && setData(arr)
      return arr
    }

    if (key) {
      const querySnapshot: any = await getDocs(
        collection(db, typeof path === "string" ? path : path.split("/"), key),
      )

      const data = querySnapshot.data()
      setData && setData(data)
    } else {
      const docRef = doc(db, typeof path === "string" ? path : path.split("/"))

      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const rawData = { ...docSnap.data(), id: docSnap.id }

        const data = map ? map({ item: rawData, path: docSnap.ref.path }) : rawData

        setData && setData(data)
      }
      return null
    }
  } catch (error) {
    console.error(`Exception trying to retrieve the ${key}`, error)
  } finally {
    setLoading && setLoading(false)
    setTimeout(() => {
      LoadingService.setLoading(false, key)
    }, 500)
  }
}

export default get
