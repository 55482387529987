import {
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import {
  getDoc,
  doc,
  query,
  where,
  onSnapshot,
  CollectionReference,
  DocumentData,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { LoadingService } from './LoadingService';
import { IRaffle } from './models';
import { Favorite, FavoriteBorder } from "@mui/icons-material"
import { formatCash } from './utils';
import { User } from 'firebase/auth';

export interface LikeComponentProps {
  handleLike: (raffleId: string) => Promise<void>;
  raffle: IRaffle;
  user: User;
  getLikesRef: (
    raffleId: string
  ) => CollectionReference<DocumentData, DocumentData>;
}
export const LikeComponent: React.FC<LikeComponentProps> = (props) => {
  const { handleLike, raffle, getLikesRef, user } = props;
  // const { t } = useTranslation()

  const [liked, setLiked] = useState<boolean>(false);
  const [likeCount, setLikeCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const fetchLikes = async (
    likesRef: CollectionReference<DocumentData, DocumentData>
  ) => {
    if (!user || !likesRef) {
      return;
    }
    
    setLoading(true);
    try {
      const likeDoc = await getDoc(doc(likesRef, user.uid));

      setLiked(likeDoc.exists() ? likeDoc.data().liked : false);
    } catch (error: any) {
      LoadingService.setLoading(true, error?.message || error, true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!raffle || !getLikesRef) {
      return;
    }

    const likesRef = getLikesRef(raffle.id); // collection(db, `raffles/${raffle.id}/likes`);
    const likesQuery = query(likesRef, where('liked', '==', true));

    fetchLikes(likesRef);

    return onSnapshot(likesQuery, {
      next: async (snap) => {
        const likeCount = snap.size;
        setLikeCount(likeCount);

        if (likeCount === 0) {
          setLiked(false);
        } else {
          try {
            if (!user) {
              return;
            }
            const likeDoc = await getDoc(doc(likesRef, user.uid));

            setLiked(likeDoc.exists() ? likeDoc.data().liked : false);
          } catch (error: any) {
            LoadingService.setLoading(true, error?.message || error, true);
          } finally {
            setLoading(false);
          }
        }

        LoadingService.setLoading(false);
      },
      error: (data) => {
        LoadingService.setLoading(true, data?.message || data, true);
      },
      complete: () => {
        LoadingService.setLoading(false);
      },
    });
  }, [raffle, user]);

  return (
    <Stack direction='row' spacing={0.1} alignItems='center'>
      <IconButton onClick={loading ? undefined : () => handleLike(raffle.id)}>
        {loading ? (
          <CircularProgress size={24} />
        ) : liked ? (
          <Favorite color='error' />
        ) : (
          <FavoriteBorder />
        )}
      </IconButton>

      <Typography variant='body2'>{formatCash(likeCount)}</Typography>
    </Stack>
  );
};
