import { AppBar, Box, Tab, Tabs, Typography, useTheme } from "@mui/material"
import { DataProvider, ListComponent } from "../../GenericListComponent"
// RaffleDetail.js
import React, { useEffect, useState } from "react"
import { doc, getDoc } from "firebase/firestore"

import { DateTime } from "luxon"
import FixTickets from "../FixTickets"
import { ImageWidget } from "../CustomWidgets"
import { RaffleEditView } from "./RaffleEditView"
import { SampleImageRenderer } from "../../SampleImageRenderer"
import SwipeableViews from "react-swipeable-views"
// import TermsIcon from "./raffle/TermsIcon"
import Wheel from "../Wheel"
import { db } from "../../firebase"
import { useParams } from "react-router-dom"
import { EmptyComponent } from "../../react-ui-components/components/EmptyComponent"

const RaffleDetail = () => {
  const { id }: any = useParams()
  const [raffle, setRaffle] = useState<any>(null)
  const [value, setValue] = useState(0)
  const theme = useTheme()

  useEffect(() => {
    const fetchRaffle = async () => {
      const docRef = doc(db, "raffles", id)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        setRaffle({ id: docSnap.id, ...docSnap.data() })
      }
    }
    fetchRaffle()
  }, [id])

  if (!raffle) return <div>Loading...</div>

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: React.SetStateAction<number>) => {
    setValue(index)
  }

  return (
    <div>
      <Box sx={{ bgcolor: "background.paper", width: "80vw" }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Assets" {...a11yProps(1)} />
            <Tab label="Tickets" {...a11yProps(2)} />
            <Tab label="Users" {...a11yProps(3)} />
            <Tab label="Tools" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <DataProvider {...ticketsData} mainId={id}>
              {!raffle ? <EmptyComponent /> : <RaffleEditView raffle={raffle} />}
            </DataProvider>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <DataProvider {...ticketsData} mainId={id}>
              <ListComponent />
            </DataProvider>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            Tickets
            {!id ? null : <Wheel raffleId={id} />}
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            Users
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            Tools
            <FixTickets raffle={raffle} />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </div>
  )
}

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

export default RaffleDetail

const ticketsData = {
  onRowClick: (list: any, navigate: any) => {
    return (_rowData: any, rowMeta: any) => {
      // const state = list[rowMeta.dataIndex]
      // const id = state.id
      // navigate(`/raffles/${id}`, { state })
    }
  },
  getListLocation(id: any) {
    return ["raffles", id, "assets"]
  },

  EDIT_TITLE: "Edit Asset",
  ADD_TITLE: "Add Asset",
  LOADING_MESSAGE: "Loading assets...",
  TITLE: "Assets",
  IMAGES_BUCKET: "assets",

  columnsProperties: [
    { hidden: true, name: "id", label: "Id" },
    { required: false, name: "disabled", label: "Disabled", type: "boolean" },

    {
      required: true,
      name: "expirationDate",
      label: "Expiration Date",
      options: {
        customBodyRender: (value: number) => {
          if (typeof value === "number") {
            return DateTime.fromMillis(value).toLocaleString(DateTime.DATETIME_FULL)
          }
          return value
        },
      },
    },
    { required: true, name: "name", label: "Name" },
    { required: true, name: "order", label: "Order" },
    {
      name: "src",
      label: "Source",
      options: {
        customBodyRender: SampleImageRenderer({ link: true }),
      },
    },
    { required: true, name: "description", label: "Description" },
  ],

  uiSchema: {
    expirationDate: {
      "ui:widget": "datetime",
    },
    src: {
      "ui:widget": ImageWidget,
    },
    description: {
      "ui:widget": "textarea",
    },
  },

  async imageProcessor(
    formData: { src: any },
    handleUpload: (arg0: any, arg1: string, arg2: any) => any,
    id: any,
    mainId: any,
  ) {
    if (formData.src) {
      formData.src = await handleUpload(id, `raffles/${mainId}/${this.IMAGES_BUCKET}`, formData.src)
    }

    return formData
  },
}
