import {
  AdminPanelSettings,
  AirplaneTicket,
  Business as BusinessIcon,
  Home as HomeIcon,
  LanguageRounded,
  LiveHelpRounded,
  LocationSearchingOutlined,
  Login as LoginIcon,
  MonetizationOn,
  Settings,
  SettingsApplicationsRounded,
  SupervisedUserCircle as UsersIcon,
} from "@mui/icons-material"
import { BrowserRouter, Navigate, Route, Routes as RoutesBase } from "react-router-dom"
import { BusinessDetail, BusinessesComponent } from "./BusinessesComponent"
import { useEffect, useState } from "react";

import AdminNotificationsPage from "./components/Notification/AdminNotificationsPage"
import AdminPanelConfig from "./components/AdminPanelConfig"
import { DisplayContentConfig } from "./components/Configuration/DisplayContentConfig";
// import EditSponsor from "./components/EditSponsor"
import ErrorPage from "./error-page"
import Home from "./components/Home"
import Login from "./components/Login"
import LoginsScreen from "./components/LoginScreen"
// import Notification from "./components/Notification"
import Onboarding from "./components/Onboarding"
import RaffleDetail from "./components/raffle/RaffleDetail"
import RaffleEdit from "./components/raffle/RaffleEdit"
import RaffleTickets from "./components/raffle/RaffleTickets"
import Raffles from "./components/raffle/Raffles"
import ReceiptContentExtractor from "./components/ReceiptContentExtractor"
import Root from "./root"
import SponsorTabs from "./components/SponsorTabs"
import Sponsors from "./components/Sponsors"
import Tickets from "./components/Tickets"
import Translations from "./components/Translations"
import UserAdminPage from "./components/Users/UserAdminPage"
import Users from "./components/Users"
import VideoIcon from "@mui/icons-material/SwitchVideo"
import VideoManager from "./components/Video/VideoManager"
import { auth } from "./firebase"
import i18n from "./i18n"
import { useAuthState } from "react-firebase-hooks/auth"
import CountryTabs from "./components/Countries/CountryTabs"
import VideoPlayer from './react-ui-components/components/Video/VideoPlayer'
import React from "react";
import { LoadingService, Loading } from "./react-ui-components/components/LoadingService";
import { PopupService } from "./react-ui-components/components/PopupService";

export const routes = [
  { name: "", title: "Home", icon: <HomeIcon />, element: <Home />, auth: false },
  { name: "login", title: "Login", icon: <LoginIcon />, element: <Login />, auth: false },
  { name: "users", title: "Users", icon: <UsersIcon />, element: <Users />, auth: true },
  {
    name: "notifications",
    title: "Users Notifications",
    icon: <UsersIcon />,
    element: <AdminNotificationsPage />,
    auth: true,
  },
  {
    name: "users/:id",
    title: "User",
    icon: <UsersIcon />,
    element: <UserAdminPage />,
    auth: true,
    hidden: true,
  },
  {
    name: "tickets",
    title: "Tickets",
    icon: <MonetizationOn />,
    element: <Tickets />,
    auth: true,
  },
  {
    name: "countries",
    title: "Countries",
    icon: <LocationSearchingOutlined />,
    element: <CountryTabs />,
    auth: true,
  },
  {
    name: "translations",
    title: "Translations",
    icon: <LanguageRounded />,
    element: <Translations />,
    auth: true,
  },
  {
    name: "onboarding",
    title: "Onboarding",
    icon: <LiveHelpRounded />,
    element: <Onboarding />,
    auth: true,
  },
  // {
  //   name: "notifications",
  //   title: "Notifications",
  //   icon: <SettingsApplicationsRounded />,
  //   element: <Notification />,
  //   auth: true,
  // },
  {
    name: "login-screen-config",
    title: "Login Screen",
    icon: <SettingsApplicationsRounded />,
    element: <LoginsScreen />,
    auth: true,
  },
  {
    name: "receipt-content-extractor",
    title: "Receipt Content Extractor",
    icon: <SettingsApplicationsRounded />,
    element: <ReceiptContentExtractor />,
    auth: true,
  },
  { name: "raffles", title: "Raffles", icon: <AirplaneTicket />, element: <Raffles />, auth: true },
  {
    name: "raffles/:id",
    title: "ViewRaffle",
    icon: <AirplaneTicket />,
    element: <RaffleDetail />,
    auth: true,
    hidden: true,
  },
  {
    name: "raffles/edit/:id",
    title: "EditRaffles",
    icon: <AirplaneTicket />,
    element: <RaffleEdit />,
    auth: true,
    hidden: true,
  },
  {
    name: "raffles/:id/generate-tickets",
    icon: <AirplaneTicket />,
    element: <RaffleTickets />,
    auth: true,
    hidden: true,
  },
  {
    name: "generate/:id",
    icon: <AirplaneTicket />,
    element: <RaffleTickets />,
    auth: true,
    hidden: true,
  },
  {
    name: "businesses",
    title: "Businesses",
    icon: <BusinessIcon />,
    element: <BusinessesComponent />,
    auth: true,
    hidden: false,
  },
  {
    name: "businesses/:id",
    title: "Businesses Details",
    icon: <BusinessIcon />,
    element: <BusinessDetail />,
    auth: true,
    hidden: true,
  },
  {
    name: "sponsors",
    title: "Sponsors",
    icon: <BusinessIcon />,
    element: <Sponsors />,
    auth: true,
  },
  {
    name: "sponsors/:id",
    title: "Sponsor",
    icon: <BusinessIcon />,
    element: <SponsorTabs />,
    auth: true,
    hidden: true,
  },
  {
    name: "pages",
    title: "Pages",
    icon: <AdminPanelSettings />,
    element: <AdminPanelConfig />,
    auth: true,
  },
  {
    name: "video-manager",
    title: "Videos Manager",
    icon: <VideoIcon />,
    element: <VideoManager />,
    auth: true,
  },
  {
    name: "video-manager/:videoId",
    title: "Videos Manager",
    icon: <VideoIcon />,
    element: <VideoPlayer video={null} />,
    auth: true,
    hidden: true,
  },  {
    name: "display-content-config",
    title: "Display Content Config",
    icon: <Settings />,
    element: <DisplayContentConfig />,
    auth: true,
  },
]

export const Routes = () => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [filteredRoutes, setFilteredRoutes] = useState([])
  const [user, loading] = useAuthState(auth)

  useEffect(() => {
    setFilteredRoutes(routes.filter((x) => x.auth === !!user) as any)

    const initialize = async () => {
      try {
        LoadingService.setLoading(true)
        await i18n.init()
        setIsInitialized(true)
      } catch (error: any) {
        PopupService.pushPopup(error.message)
      } finally {
        LoadingService.setLoading(false)
      }
    }

    initialize()
  }, [user])

  if (!isInitialized || loading) {
    return <Loading />
  }
  // return JSON.stringify(filteredRoutes)
  return (
    <div style={{ width: "80vw" }}>
      <BrowserRouter>
        <RoutesBase>
          <Route path="" element={<Root />} errorElement={ErrorPage as any} loader={Loading}>
            {filteredRoutes.map((item: any, i) => (
              <Route key={`${item.name}-${i}`} path={item.name} element={item.element} />
            ))}
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </RoutesBase>
      </BrowserRouter>
    </div>
  )
}
