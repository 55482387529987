import { AppBar, Box, Tab, Tabs, Typography, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"
import SwipeableViews from "react-swipeable-views"
import Main from "./Main"
import EditAvailableLocations from "./EditAvailableLocations"
import { doc, getDoc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { ILocation } from "../../react-ui-components/components/models/ILocation"
import LocationSelector from "../../react-ui-components/components/location/LocationSelector"
import { LoadingService } from "../../react-ui-components/components/LoadingService"
import { PopupService } from "../../react-ui-components/components/PopupService"
import { LocationProvider } from "../../LocationSelector"

const CountryTabs = () => {
  const [value, setValue] = useState(0)
  const theme = useTheme()
  const [currentLocation, setCurrentLocation] = useState<ILocation | null>({})
  const [testLocation, setTestLocation] = useState<any>({})

  const [selectedCountry, setSelectedCountry] = useState<string>(currentLocation?.country || "")
  const [selectedState, setSelectedState] = useState<string>(currentLocation?.state || "")
  const [selectedCity, setSelectedCity] = useState<string>(currentLocation?.city || "")

  const fetchAvailableLocations = async () => {
    const querySnapshot = await getDoc(doc(db, "app-configurations", "availableLocations"))
    const data: any = querySnapshot.data()
    setCurrentLocation(data)
  }

  const updateAvailableLocations = async (data: ILocation) => {
    try {
      LoadingService.show()
      const docRef = doc(db, "app-configurations", "availableLocations")

      await updateDoc(docRef, data as any)
      setCurrentLocation(data)
    } catch (error) {
      PopupService.pushPopup(error.message)
    } finally {
      LoadingService.hide()
    }
  }

  useEffect(() => {
    fetchAvailableLocations()
  }, [])

  const handleChange = (event: unknown, newValue: React.SetStateAction<number>) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index: React.SetStateAction<number>) => {
    setValue(index)
  }

  const handleLocationChange = (name: string, value: string) => {
    // let locationObj = { ...userSelectedFilter }
    switch (name) {
      case "country":
        // setSelectedCountry(value)
        // locationObj = { ...locationObj, country: value, state: "", city: "" }
        setTestLocation({
          country: value,
          state: "",
          city: "",
        })
        break
      case "state":
        // setSelectedState(value)
        // locationObj = { ...locationObj, state: value, city: "" }
        setTestLocation({
          country: currentLocation.country,
          state: value,
          city: "",
        })
        break
      case "city":
        // setSelectedCity(value)
        // locationObj = { ...locationObj, city: value }
        setTestLocation({
          country: currentLocation.country,
          state: currentLocation.state,
          city: value,
        })
        break
      default:
        return
    }
    // if (validateLocation(locationObj)) {
    //   setUserSelectedFilter(locationObj)
    // }
  }

  return (
    <LocationProvider>
      <Box sx={{ bgcolor: "background.paper", width: "80vw" }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Translation Countries" {...a11yProps(0)} />
            <Tab label="Raffles Countries" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Main />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <div className="flex flex-col gap-1">
              {/* <LocationSelector
                rootProps={{
                  className: "flex flex-row gap-1",
                }}
                onChange={function (name: string, value: string): void {
                  console.log(name)
                }}
                label={"Available Locations"}
                value={testLocation as any}
              /> */}

              <div>Current Location:{JSON.stringify(currentLocation || {})}</div>

              <div>
                <EditAvailableLocations
                  currentLocations={currentLocation}
                  updateLocation={setCurrentLocation}
                  sugmit={updateAvailableLocations} // userSelectedCountry={selectedCountry}
                  // userSelectedState={selectedState}
                  // userSelectedCity={selectedCity}
                  // onChange={handleChange}
                />
              </div>
            </div>
          </TabPanel>
        </SwipeableViews>
      </Box>
    </LocationProvider>
  )
}

function TabPanel(props: { [x: string]: unknown; children: any; value: unknown; index: unknown }) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

export default CountryTabs
